.social_buttons {
    height: 65px;
    width: 100%;
    border-bottom: solid 1px #00808c;
}

.social_button {
    width: 24%;
    height: 65px;
    padding-left: 45px;
    padding-top: 15px;
    float: left;
    cursor: pointer;
}

#vk {
    padding-left: 55px;
}

#fb {
    padding-left: 65px;
}

#flickr {
    padding-left: 65px;
}

.social_button:hover {
    border-bottom: solid 3px #00808c;
}

.social_button.active {
    border-bottom: solid 3px #00808c;
    background-color: #c7edef;
}

.social_button_icon {
    /*
width: 39px;
height: 39px;
*/
    max-width: 34px;
    color: #5a5b5d;
    font-size: 13px;
    padding-top: 7px;
    text-align: center;
    float: left;
}

.social_button_icon.border {
    /*border: solid 1.5px #000;*/
    border-radius: 50px;
}

.social_button_text {
    text-align: center;
    float: left;
    margin-left: 5px;
    margin-top: 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #00808c;
    font-weight: 500;
}

.circle {
    float: left;
    width: 11px;
    height: 11px;
    background-color: #5a5b5d;
    border: solid 1px #5a5b5d;
    border-radius: 50px;
    margin-left: 3px;
}

.social_button_icon>.fa-2x {
    font-size: 22px;
}

.flickr_ico {
    padding: 8px 3px;
}

@media screen and (max-width: 991px) {
    .social_button {
        width: 23%;
        padding-left: 35px;
    }
    .social_button_icon>.material-icons {
        font-size: 34px;
    }
    .social_button_icon>.fa-2x {
        font-size: 30px;
    }
    .circle {
        width: 15px;
        height: 15px;
    }
    .social_button_text {
        display: none;
    }
    .social_button_icon {
        max-width: 64px;
    }
    #pc {
        padding-left: 12px;
    }
    #vk {
        padding-left: 30px;
    }
    #fb {
        padding-left: 35px;
    }
    #flickr {
        padding-left: 35px;
    }
}

.thecube {
    width: 96%;
    height: 118px;
    position: relative;
    left: 1px;
    top: -164px;
    z-index: 1;
    background-color: #ececec;
    padding-top: 5px;
}

.cssload-thecube {
    width: 39px;
    height: 39px;
    margin: 0 auto;
    margin-top: 35px;
    position: relative;
    z-index: 1;
    transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
    position: relative;
    transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
}

.cssload-thecube .cssload-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
}

.cssload-thecube .cssload-cube:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(151, 210, 220);
    animation: cssload-fold-thecube 2.76s infinite linear both;
    -o-animation: cssload-fold-thecube 2.76s infinite linear both;
    -ms-animation: cssload-fold-thecube 2.76s infinite linear both;
    -webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
    -moz-animation: cssload-fold-thecube 2.76s infinite linear both;
    transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;
    -moz-transform-origin: 100% 100%;
}

.cssload-thecube .cssload-c2 {
    transform: scale(1.1) rotateZ(90deg);
    -o-transform: scale(1.1) rotateZ(90deg);
    -ms-transform: scale(1.1) rotateZ(90deg);
    -webkit-transform: scale(1.1) rotateZ(90deg);
    -moz-transform: scale(1.1) rotateZ(90deg);
}

.cssload-thecube .cssload-c3 {
    transform: scale(1.1) rotateZ(180deg);
    -o-transform: scale(1.1) rotateZ(180deg);
    -ms-transform: scale(1.1) rotateZ(180deg);
    -webkit-transform: scale(1.1) rotateZ(180deg);
    -moz-transform: scale(1.1) rotateZ(180deg);
}

.cssload-thecube .cssload-c4 {
    transform: scale(1.1) rotateZ(270deg);
    -o-transform: scale(1.1) rotateZ(270deg);
    -ms-transform: scale(1.1) rotateZ(270deg);
    -webkit-transform: scale(1.1) rotateZ(270deg);
    -moz-transform: scale(1.1) rotateZ(270deg);
}

.cssload-thecube .cssload-c2:before {
    animation-delay: 0.35s;
    -o-animation-delay: 0.35s;
    -ms-animation-delay: 0.35s;
    -webkit-animation-delay: 0.35s;
    -moz-animation-delay: 0.35s;
}

.cssload-thecube .cssload-c3:before {
    animation-delay: 0.69s;
    -o-animation-delay: 0.69s;
    -ms-animation-delay: 0.69s;
    -webkit-animation-delay: 0.69s;
    -moz-animation-delay: 0.69s;
}

.cssload-thecube .cssload-c4:before {
    animation-delay: 1.04s;
    -o-animation-delay: 1.04s;
    -ms-animation-delay: 1.04s;
    -webkit-animation-delay: 1.04s;
    -moz-animation-delay: 1.04s;
}

@keyframes cssload-fold-thecube {
    0%,
    10% {
        transform: perspective(73px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        transform: perspective(73px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        transform: perspective(73px) rotateY(180deg);
        opacity: 0;
    }
}

@-o-keyframes cssload-fold-thecube {
    0%,
    10% {
        -o-transform: perspective(73px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -o-transform: perspective(73px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -o-transform: perspective(73px) rotateY(180deg);
        opacity: 0;
    }
}

@-ms-keyframes cssload-fold-thecube {
    0%,
    10% {
        -ms-transform: perspective(73px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -ms-transform: perspective(73px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -ms-transform: perspective(73px) rotateY(180deg);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-fold-thecube {
    0%,
    10% {
        -webkit-transform: perspective(73px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -webkit-transform: perspective(73px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -webkit-transform: perspective(73px) rotateY(180deg);
        opacity: 0;
    }
}

@-moz-keyframes cssload-fold-thecube {
    0%,
    10% {
        -moz-transform: perspective(73px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        -moz-transform: perspective(73px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        -moz-transform: perspective(73px) rotateY(180deg);
        opacity: 0;
    }
}

.back {
    z-index: 1;
    width: 21%;
    height: 165px;
    position: absolute;
    margin-left: 7px;
    margin-top: -4px;
    background-color: #fff;
    border: 1px solid #bcbcbc;
}

.back.back2 {
    z-index: 0;
    margin-left: 10px;
    margin-top: -7px;
}

.image-info>.name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #6d6e71;
    /*#00965e;*/
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    width: 100%;
    height: 20px;
    padding-left: 5px;
}

.image-info>.count {
    color: #333639;
    text-decoration: none;
    font-size: 11px;
    padding-left: 5px;
}

.image-container .image-info {
    border: 1px solid #bcbcbc;
    background-color: #f2f6f8;
    border-top: none;
    width: 97%;
    position: relative;
    z-index: 2;
    height: 45px;
}

.photos>h4 {
    margin-left: 8px;
    color: #6d6e71;
    /*#00965e;*/
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
}

.photos>h4>a {
    cursor: pointer;
    color: #1c75bc;
}

.loader {
    margin: 15em auto;
    font-size: 8px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid #fff;
    border-right: 0.5em solid #fff;
    border-bottom: 0.5em solid #fff;
    border-left: 0.5em solid #c7edef;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.upload-preview {
    height: 100%;
}

.image-container {
    width: 22%;
    height: 120px;
}

.image-preview {
    border: 1px solid #bcbcbc;
}

.image-preview {
    position: relative;
    height: inherit;
}

.img-preview {
    /* kak
        background-size: cover;
        background-position-y: center;
        background-position-x: center;
        */
    /*height: 78px;*/
    z-index: 11;
    background-repeat: no-repeat;
    overflow: hidden;
    /* kak */
}

.photos {
    position: relative;
    width: 100%;
    height: 90%;
}

.ordering-top {
    overflow: hidden;
    margin-bottom: 35px;
}

.ordering-top div {
    float: left;
    height: 25px;
    /*border-bottom:3px solid #e1e0e0;*/
    padding-left: 30px;
    margin-bottom: 8px;
    line-height: 25px;
    font-size: 16px;
    color: #e1e0e0;
}

.ordering-top div.active {
    /*border-color:#19bc9c;*/
    color: #555555;
}

.ordering-top .ordering-top-title.active {
    width: 185px;
    padding-left: 0;
    color: #555555;
}

.ordering-top-step {
    background-position: top right;
    background-repeat: no-repeat;
}

.ordering-top-step1.active {
    background-position: bottom right;
}

.ordering-top-step2.active {
    background-position: bottom right;
}

.ordering-top-step3.active {
    background-position: right -36px;
}

.ordering-top-step1 {
    width: 230px;
    background-image: url("https://gmprint.ru/images/order/steps/step-arrow.png");
}

.ordering-top-step2 {
    width: 260px;
    background-image: url("https://gmprint.ru/images/order/steps/step-arrow.png");
}

.ordering-top-step3 {
    width: 240px;
    background-image: url("https://gmprint.ru/images/order/steps/step3-checked.png");
}

.ordering-top-step span {
    padding-left: 37px;
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    line-height: 25px;
    height: 25px;
}

.ordering-top-step a {
    padding-left: 37px;
    background-position: bottom left;
    background-repeat: no-repeat;
    display: block;
    line-height: 25px;
    height: 25px;
}

.ordering-top-step a:hover {
    background-position: top left;
}

.ordering-top-step1 a.pressed1 {
    background-image: url("https://gmprint.ru/images/order/steps/step1press.png");
    height: 29px;
    background-position-y: -1px;
    background-position-x: -1px;
}

.ordering-top-step2 a.pressed2 {
    background-image: url("https://gmprint.ru/images/order/steps/step2press.png");
    height: 29px;
    background-position-y: -1px;
    background-position-x: -1px;
}

.ordering-top-step1 span {
    background-image: url("https://gmprint.ru/images/order/steps/step1.png");
}

.ordering-top-step1 a {
    background-image: url("https://gmprint.ru/images/order/steps/step1.png");
}

.ordering-top-step2 span {
    background-image: url("https://gmprint.ru/images/order/steps/step2.png");
}

.ordering-top-step2 a {
    background-image: url("https://gmprint.ru/images/order/steps/step2.png");
}

.ordering-top-step2 a:hover {
    background-position: center left;
}

.ordering-top-step3 span {
    background-image: url("https://gmprint.ru/images/order/steps/step3.png");
}

.ordering-top-step.active span {
    background-position: bottom left;
}

.ordering-top-line {
    display: block;
    height: 3px;
    width: 100%;
    overflow: hidden;
    background-color: #e1e0e0;
}

.ordering-top-line span {
    width: 0;
    display: block;
    height: 3px;
    background-color: #19bc9c;
}

.upload-section {}

.upload-section-title {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
    color: #1abc9c;
}

.upload-section-title span {
    font-size: 14px;
    padding-left: 23px;
}

.upload-section-title span a {
    color: #1abc9c;
}

.upload-section-item {
    border: 1px solid #9c9c9c;
    padding: 18px 17px;
    overflow: hidden;
    border-radius: 7px;
    margin-bottom: 16px;
}

.upload-section-item-left {
    width: 180px;
    float: left;
    margin-right: 32px;
}

.upload-section-item-right {
    width: 77%;
    float: left;
    position: relative;
}

.upload-section-item-image {
    background: #ececed url("https://gmprint.ru/images/order/upload/uploaded-image-area.png") center center no-repeat;
    width: 178px;
    height: 178px;
    border-radius: 7px;
    margin-bottom: 17px;
    border: 1px solid #ECECED;
}

.upload-section-item-image-4-box {
    overflow: hidden;
    background-color: #ececec;
    border-radius: 7px;
    width: 180px;
    height: 180px;
    margin-bottom: 17px;
}

.upload-section-item-image-4 {
    background: #ececed url("https://gmprint.ru/images/order/upload/uploaded-image-area.png") center center no-repeat;
    width: 77px;
    height: 77px;
    border-radius: 7px;
    margin-top: 9px;
    margin-left: 9px;
    float: left;
}

.upload-section-item-price {
    position: absolute;
    font-size: 22px;
    width: 140px;
    text-align: right;
    right: 0;
    line-height: 22px;
}

.upload-section-item-right-controls {
    position: absolute;
    right: 3px;
    top: 207px;
    height: 18px;
    width: 66px;
}

.upload-section-item-right-controls a {
    float: left;
    width: 18px;
    height: 18px;
    margin-left: 15px;
}

.upload-section-item-right-controls a.edit-btn {
    background: url("https://gmprint.ru/images/order/upload/edit.png") 0 0 no-repeat;
}

.upload-section-item-right-controls a.edit-btn:hover {
    background: url("https://gmprint.ru/images/order/upload/edit.png") left bottom no-repeat;
}

.upload-section-item-right-controls a.delete-btn {
    background: url("https://gmprint.ru/images/order/upload/delete.png") no-repeat;
}

.upload-section-item-right-controls a.delete-btn:hover {
    background-position: left bottom;
}

.upload-section-item-name {
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    margin-bottom: 10px;
}

.upload-section-item-name span {
    font-size: 14px;
}

.upload-section-item-date {
    font-size: 14px;
    line-height: 25px;
    padding-top: 10px;
}

.upload-section-item-date .separator {
    color: #c7c7c7;
    padding: 0 5px;
}

.upload-section-item-info {
    overflow: hidden;
    font-size: 14px;
    line-height: 25px;
    color: #8d8d8d;
}

.upload-section-item-info span {
    /*color:#b7b7b7;*/
}

.upload-section-item-info-features {
    float: left;
    width: 230px;
}

.upload-section-item-info-ppp {
    float: left;
    width: 270px;
}

.upload-section-item-design {
    position: absolute;
    line-height: 37px;
    margin-top: 197px;
    font-size: 14px;
}

.upload-section-item-design a {
    /*color:#1abc9c;*/
}

.upload-section-item-design .free-design-btn {
    width: 150px;
}

.upload-section-item-design .free-design-btn:hover {}

.upload-section-item-design .separator {
    color: #c7c7c7;
    padding: 0 5px;
}

.upload-section-controls {
    overflow: hidden;
}

.upload-section-controls .next-btn {
    width: 66px;
    /*font-size:17px;*/
    float: right;
    margin-left: 27px;
    /*font-weight:600;*/
}

.upload-section-total {
    text-align: right;
    padding: 14px 0 30px;
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    overflow: hidden;
}

.upload-section-total .upload-section-total-title {
    float: right;
}

.upload-section-total .upload-section-add-new-order {
    float: left;
    color: #1abc9c;
    font-size: 22px;
    padding-left: 18px;
}

.upload-section-add-new-order a {
    color: #1abc9c;
}

.upload-section-total-title {
    text-align: right;
    font-size: 16px;
}

.upload-section-total-price {
    font-size: 22px;
    font-weight: 400;
}


/* rotten */


/* begin of basket/upload */

.upload-section-item {}

.upload-section-item-rotten {
    z-index: 10;
    border: 2px solid #ff0000;
    border-radius: 7px;
    padding: 17px 16px;
}

.upload-section-item-name {
    /*
	z-index:102;
	position:relative;
*/
}

.rotten-order {
    position: absolute;
    width: 1004px;
    height: 266px;
    border-radius: 7px;
    margin: -17px 0 0 -16px;
    z-index: 12;
    background: rgba(255, 255, 255, 0.90);
}

.rotten-order-text {
    padding: 20px 0 25px 229px;
    font-size: 14px;
    color: #ff0000;
    line-height: 26px;
}

.rotten-order-controls {
    overflow: hidden;
    padding-left: 228px;
}

.rotten-order-controls a {
    margin-right: 22px;
    width: 124px;
    float: left;
}


/* end of basket/upload */

.rotten-order-name {
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    margin-left: 227px;
    margin-top: 18px;
}

.rotten-order-name span {
    font-size: 14px;
}

.message-basket-is-empty {
    font-size: 14px;
}

.message-basket-is-empty a {
    color: #1abc9c;
}

.empty_basket_title {
    font-size: 22px;
    margin-bottom: 25px;
}


/******************************************************************************************************/


/******************************************************************************************************/


/******************************************************************************************************/


/******************************************************************************************************/


/******************************************************************************************************/

#popup-upload_layouts-window {
    max-width: 910px;
}

.popup-upload_layouts-content {
    padding: 30px 35px 30px;
}

.upload_layouts-form {
    border: 1px solid #a9a9a9;
    border-radius: 7px;
    overflow: hidden;
}

.upload_layouts-form-lay {
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    position: absolute;
    z-index: 90;
    display: none;
    margin-top: -1px;
    margin-left: -1px;
}

.upload_layouts-form-top {
    background: #f3f3f3;
    padding: 17px 24px;
    overflow: hidden;
}

.upload_layouts-form-top-view {
    width: 100px;
    float: left;
}

.upload_layouts-form-top-view a {
    width: 96px;
    height: 33px;
    display: block;
    line-height: 33px;
    border: 2px solid #1abc9c;
    border-radius: 7px;
    color: #1abc9c;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background: #ffffff;
    text-decoration: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.upload_layouts-form-top-view a:hover {
    background: #1abc9c;
    color: #ffffff;
}

.upload_layouts-form-top-button {
    width: 109px;
    float: left;
}

.upload_layouts-form-top-button a {
    width: 105px;
    height: 33px;
    display: block;
    background: #ffffff;
    line-height: 33px;
    border: 2px solid #d5d5d5;
    border-radius: 7px;
    color: #d5d5d5;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}

.upload_layouts-form-top-button a.active {
    border: 2px solid #1abc9c;
    background: #1abc9c;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.upload_layouts-form-top-button a.active:hover {
    color: #1abc9c;
    background: #ffffff;
}

.upload_layouts-form-top-files {
    width: 625px;
    min-height: 32px;
    float: left;
    border: 1px solid #a2a2a2;
    border-radius: 7px;
    background: #ffffff;
    margin: 0 25px;
    padding: 0 3px 3px 0;
}

.upload_layouts-form-top-files-item {
    float: left;
    height: 30px;
    background: #1abc9c;
    border-radius: 5px;
    padding: 0 26px 0 14px;
    overflow: hidden;
    margin: 3px 0 0 3px;
}

.upload_layouts-form-top-files-item-delete {
    background: url("https://gmprint.ru/images/order/upload/upload-form-top-files-item-delete.png") no-repeat;
    height: 13px;
    width: 13px;
    cursor: pointer;
    float: left;
    margin-right: 16px;
    margin-top: 9px;
}

.upload_layouts-form-top-files-item-name {
    color: #ffffff;
    float: left;
    line-height: 30px;
    font-size: 14px;
}

.upload_layouts-form-list {
    overflow: hidden;
}

.upload_layouts-form-list li {
    /*position: relative;*/
    /*border-top: 1px solid #e1e0e0;*/
    /*clear: both;*/
}

.upload_layouts-form-list li.not-active {
    display: none;
}

.with-files #layouts_filelist {
    display: block;
}

.upload_layouts-form-item-percent::after {
    content: '';
    position: absolute;
    /* ���������� ���������������� */
    left: 17px;
    bottom: -11px;
    /* ��������� ������������ */
    border: 6px solid transparent;
    /* ���������� ������� */
    border-top: 5px solid #1abc9c;
    /* ��������� ����������� */
}

.upload_layouts-form-item-percent {
    position: absolute;
    width: 46px;
    text-align: center;
    left: 0px;
    font-size: 14px;
    font-weight: 600;
    height: 24px;
    line-height: 24px;
    z-index: 10;
    display: block;
    border-radius: 3px;
    top: -32px;
    background: #1abc9c;
    color: #ffffff;
    -moz-transition: left 300ms linear;
    -webkit-transition: left 300ms linear;
    -o-transition: left 300ms linear;
    transition: left 300ms linear;
}

.upload_layouts-form-item-status .checked {
    margin: 3px 0 0;
    width: 14px;
    height: 11px;
    background: url("https://gmprint.ru/images/upload_layouts/checked.png") no-repeat;
}

.upload_layouts-form-item-status .chained {
    margin: 0px 0 0;
    width: 16px;
    height: 16px;
    background: url("https://gmprint.ru/images/upload_layouts/chained.png") no-repeat;
}

.upload_layouts-form-item-status .loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid rgba(26, 188, 156, 1);
    border-right: 3px solid rgba(26, 188, 156, 1);
    border-bottom: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    -webkit-animation: load8 2.1s infinite linear;
    animation: load8 2.1s infinite linear;
}

.upload_layouts-form-item-status .loader,
.upload_layouts-form-item-status .loader:after {
    border-radius: 50%;
    width: 11px;
    height: 11px;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/*.upload_layouts-form-item-status {*/


/*width: 30px;*/


/*height: 36px;*/


/*padding-top: 18px;*/


/*float: left;*/


/*display: block;*/


/*z-index: 10;*/


/*position: relative;*/


/*}*/


/*.upload_layouts-form-item-name {*/


/*padding-left: 0;*/


/*width: 660px;*/


/*font-size: 14px;*/


/*height: 54px;*/


/*line-height: 54px;*/


/*float: left;*/


/*z-index: 10;*/


/*position: relative;*/


/*overflow: hidden;*/


/*}*/

.upload_layouts-form-item-size {
    width: 100px;
    float: left;
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    text-align: right;
    z-index: 10;
    position: relative;
}

.upload_layouts-form-item-delete,

/*.upload_layouts-form-item-orders-delete {*/


/*width: 15px;*/


/*float: right;*/


/*z-index: 10;*/


/*position: relative;*/


/*}*/

.upload_layouts-form-item-delete a,
.upload_layouts-form-item-orders-delete a {
    /*height: 15px;*/
    /*width: 15px;*/
    margin: 19px auto 0;
    display: block;
}


/*.upload_layouts-form-item-line {*/


/*height: 54px;*/


/*position: absolute;*/


/*clear: both;*/


/*z-index:1;*/


/*width: 820px;*/


/*}*/


/*.upload_layouts-form-item-line span {*/


/*width: 0;*/


/*height: 54px;*/


/*background: url(https://gmprint.ru/images/upload_layouts/progress.png) repeat-x;*/


/*display: block;*/


/*z-index: 1;*/


/*-moz-transition: width 300ms linear;*/


/*-webkit-transition: width 300ms linear;*/


/*-o-transition: width 300ms linear;*/


/*transition: width 300ms linear;*/


/*}*/

.popup-upload_layouts-bottom {
    /*display: none;*/
    overflow: hidden;
    clear: both;
}

.popup-upload_layouts-bottom.active {
    display: block;
}

.popup-upload_layouts-comment {
    margin: 0 0 25px;
    padding-top: 15px;
    border-top: 1px solid #e1e0e0;
}

.popup-upload_layouts-comment .text {
    margin-bottom: 10px;
}

.popup-upload_layouts-comment textarea {
    width: 784px;
    height: 100px;
}

.popup-upload_layouts-attention {
    font-size: 14px;
    margin: 18px 0 34px;
    background: url("https://gmprint.ru/images/info.png") no-repeat;
    padding-left: 30px;
}

.popup-upload_layouts-attention a {
    color: #1abc9c;
    font-weight: 600;
}

.popup-upload_layouts-req {
    overflow: hidden;
    margin-bottom: 25px;
}

.popup-upload_layouts-req-checkbox {
    float: left;
    width: 21px;
}

.popup-upload_layouts-req-checkbox a {
    width: 17px;
    height: 17px;
    display: block;
    border: 2px solid #1abc9c;
    border-radius: 5px;
}

.popup-upload_layouts-req-checkbox a.active {
    background: url("https://gmprint.ru/images/order/upload/upload-req-checkbox.png") center center no-repeat;
}

.popup-upload_layouts-req-checkbox a.active-disabled {
    background: url("https://gmprint.ru/images/order/upload/upload-req-checkbox.png") center center no-repeat;
}

.popup-upload_layouts-req-text {
    float: left;
    margin-left: 10px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
}

.popup-upload_layouts-req-text a {
    color: #1abc9c;
}

.popup-upload_layouts-controls {
    width: 360px;
}

.popup-upload_layouts-controls a.correction-btn {
    width: 240px;
    float: right;
}

.popup-upload_layouts-controls a.save-btn {
    width: 85px;
    float: left;
    margin-right: 20px;
}

.popup-upload_layouts-controls a.cancel-btn {
    width: 75px;
    float: left;
    margin-right: 20px;
}


/*.new-upload_layouts-form-top {*/


/*border: 1px dashed #1abc9c;*/


/*background: #f5fffd;*/


/*border-radius: 7px;*/


/*width: 818px;*/


/*height: 170px;*/


/*padding: 1px;*/


/*}*/

.new-upload_layouts-form-text {
    margin: 25px 0 0 0;
}

.new-upload_layouts-form-text-image {
    margin: 28px auto 0;
    width: 52px;
    cursor: pointer;
    height: 64px;
}

.new-upload_layouts-form-subtitle {
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.new-upload_layouts-form-subtitle span {
    cursor: pointer;
    font-size: 22px;
}

.new-upload_layouts-form-subtitle span:hover {
    text-decoration: none;
}

#drop-target.dragover {
    /*background: #e8f8f5;*/
}

#drop-target {
    cursor: pointer;
}

.new-upload_layouts-form-top .st0 {
    fill: #555555;
}

.new-upload_layouts-form-top.active .st0 {
    fill: #1abc9c;
}

.new-upload_layouts-form-top.active {
    border: 2px solid #1abc9c;
    background: #eafffb;
    color: #1abc9c;
    padding: 0;
}

.upload_layout-add-link-wrapper {
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 40px;
}

.upload_layout-add-link-wrapper .text {
    font-size: 14px;
    margin-bottom: 10px;
}

.upload_layout-add-link-wrapper .field {}

.upload_layout-add-link-wrapper .field input {
    margin: 0;
    padding: 0 0 0 23px;
    width: 675px;
    float: left;
    border: 2px solid #9c9c9c;
    border-radius: 6px 0 0 6px;
    height: 32px;
    line-height: 32px;
    border-right: 0;
}

.upload_layout-add-link-wrapper .field input:focus {
    border-color: #1abc9c;
}

.upload_layout-add-link-wrapper .btn {
    width: 120px;
    float: left;
}

.upload_layout-add-link-wrapper .btn a {
    display: block;
    text-align: center;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    color: #ffffff;
    background: #1abc9c;
    height: 36px;
    line-height: 36px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
}

.upload_layout-add-link-wrapper .btn a:hover {
    background-color: #25deb3;
}

.upload_layout-warning {
    background: #f26363;
    padding: 17px 0;
    color: #ffffff;
    position: relative;
    display: none;
    font-size: 14px;
}

.upload_layout-warning .text {
    padding: 0 50px 0 30px;
    line-height: 18px;
}

.upload_layout-warning a {
    color: #ffffff;
}

.upload_layout-warning .close {
    position: absolute;
    left: 795px;
    font-size: 20px;
    width: 25px;
    cursor: pointer;
    line-height: 20px;
}

.file-uploading {
    color: #000000;
}

a.delete-ico {
    background: url(https://gmprint.ru/images/account/delete.png) no-repeat;
}

li {
    list-style-type: none;
}

.new-upload_layouts-form-top {
    width: 800px;
}

.new-upload_layouts-form {
    margin-top: 30px;
}

.popup-top-green button.mfp-close {
    background: url(https://gmprint.ru/images/popup-close-white.png) no-repeat;
}

body button.mfp-close {
    display: none;
}

button.mfp-close {
    background: url(https://gmprint.ru/images/popup-close.png) no-repeat;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.mfp-close {
    width: 29px;
    height: 29px;
    line-height: 29px;
    position: absolute;
    right: 16px;
    top: 12px;
    text-decoration: none;
    text-align: center;
    color: white;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.ordering-top {
    overflow: hidden;
    margin-bottom: 35px;
}

.ordering-top div {
    float: left;
    height: 25px;
    /*border-bottom:3px solid #e1e0e0;*/
    padding-left: 30px;
    margin-bottom: 8px;
    line-height: 25px;
    font-size: 16px;
    color: #e1e0e0;
}

.ordering-top div.active {
    /*border-color:#19bc9c;*/
    color: #555555;
}

.ordering-top .ordering-top-title.active {
    width: 185px;
    padding-left: 0;
    color: #555555;
}

.ordering-top-step {
    background-position: top right;
    background-repeat: no-repeat;
}

.ordering-top-step1.active {
    background-position: bottom right;
}

.ordering-top-step2.active {
    background-position: bottom right;
}

.ordering-top-step3.active {
    background-position: right -36px;
}

.ordering-top-step1 {
    width: 230px;
    background-image: url("https://gmprint.ru/images/order/steps/step-arrow.png");
}

.ordering-top-step2 {
    width: 260px;
    background-image: url("https://gmprint.ru/order/steps/step-arrow.png");
}

.ordering-top-step3 {
    width: 240px;
    background-image: url("https://gmprint.ru/order/steps/step3-checked.png");
}

.ordering-top-step span {
    padding-left: 37px;
    background-position: top left;
    background-repeat: no-repeat;
    display: block;
    line-height: 25px;
    height: 25px;
}

.ordering-top-step a {
    padding-left: 37px;
    background-position: bottom left;
    background-repeat: no-repeat;
    display: block;
    line-height: 25px;
    height: 25px;
}

.ordering-top-step a:hover {
    background-position: top left;
}

.ordering-top-step1 a.pressed1 {
    background-image: url("https://gmprint.ru/order/steps/step1press.png");
    height: 29px;
    background-position-y: -1px;
    background-position-x: -1px;
}

.ordering-top-step2 a.pressed2 {
    background-image: url("https://gmprint.ru/order/steps/step2press.png");
    height: 29px;
    background-position-y: -1px;
    background-position-x: -1px;
}

.ordering-top-step1 span {
    background-image: url("https://gmprint.ru/images/order/steps/step1.png");
}

.ordering-top-step1 a {
    background-image: url("https://gmprint.ru/images/order/steps/step1.png");
}

.ordering-top-step2 span {
    background-image: url("https://gmprint.ru/images/order/steps/step2.png");
}

.ordering-top-step2 a {
    background-image: url("https://gmprint.ru/images/order/steps/step2.png");
}

.ordering-top-step2 a:hover {
    background-position: center left;
}

.ordering-top-step3 span {
    background-image: url("https://gmprint.ru/images/order/steps/step3.png");
}

.ordering-top-step.active span {
    background-position: bottom left;
}

.ordering-top-line {
    display: block;
    height: 3px;
    width: 100%;
    overflow: hidden;
    background-color: #e1e0e0;
}

.ordering-top-line span {
    width: 0;
    display: block;
    height: 3px;
    background-color: #19bc9c;
}

.upload-section {}

.upload-section-title {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
    color: #1abc9c;
}

.upload-section-title span {
    font-size: 14px;
    padding-left: 23px;
}

.upload-section-title span a {
    color: #1abc9c;
}

.upload-section-item {
    border: 1px solid #9c9c9c;
    padding: 18px 17px;
    overflow: hidden;
    border-radius: 7px;
    margin-bottom: 16px;
}

.upload-section-item-left {
    width: 180px;
    float: left;
    margin-right: 32px;
}

.upload-section-item-right {
    width: 77%;
    float: left;
    position: relative;
}

.upload-section-item-image {
    background: #ececed url("https://gmprint.ru/images/order/upload/uploaded-image-area.png") center center no-repeat;
    width: 178px;
    height: 178px;
    border-radius: 7px;
    margin-bottom: 17px;
    border: 1px solid #ECECED;
}

.upload-section-item-image-4-box {
    overflow: hidden;
    background-color: #ececec;
    border-radius: 7px;
    width: 180px;
    height: 180px;
    margin-bottom: 17px;
}

.upload-section-item-image-4 {
    background: #ececed url("https://gmprint.ru/images/order/upload/uploaded-image-area.png") center center no-repeat;
    width: 77px;
    height: 77px;
    border-radius: 7px;
    margin-top: 9px;
    margin-left: 9px;
    float: left;
}

.upload-section-item-price {
    position: absolute;
    font-size: 22px;
    width: 140px;
    text-align: right;
    right: 0;
    line-height: 22px;
}

.upload-section-item-right-controls {
    position: absolute;
    right: 3px;
    top: 207px;
    height: 18px;
    width: 66px;
}

.upload-section-item-right-controls a {
    float: left;
    width: 18px;
    height: 18px;
    margin-left: 15px;
}

.upload-section-item-right-controls a.edit-btn {
    background: url("https://gmprint.ru/images/order/upload/edit.png") 0 0 no-repeat;
}

.upload-section-item-right-controls a.edit-btn:hover {
    background: url("https://gmprint.ru/images/order/upload/edit.png") left bottom no-repeat;
}

.upload-section-item-right-controls a.delete-btn {
    background: url("https://gmprint.ru/images/order/upload/delete.png") no-repeat;
}

.upload-section-item-right-controls a.delete-btn:hover {
    background-position: left bottom;
}

.upload-section-item-name {
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    margin-bottom: 10px;
}

.upload-section-item-name span {
    font-size: 14px;
}

.upload-section-item-date {
    font-size: 14px;
    line-height: 25px;
    padding-top: 10px;
}

.upload-section-item-date .separator {
    color: #c7c7c7;
    padding: 0 5px;
}

.upload-section-item-info {
    overflow: hidden;
    font-size: 14px;
    line-height: 25px;
    color: #8d8d8d;
}

.upload-section-item-info span {
    /*color:#b7b7b7;*/
}

.upload-section-item-info-features {
    float: left;
    width: 230px;
}

.upload-section-item-info-ppp {
    float: left;
    width: 270px;
}

.upload-section-item-design {
    position: absolute;
    line-height: 37px;
    margin-top: 197px;
    font-size: 14px;
}

.upload-section-item-design a {
    /*color:#1abc9c;*/
}

.upload-section-item-design .free-design-btn {
    width: 150px;
}

.upload-section-item-design .free-design-btn:hover {}

.upload-section-item-design .separator {
    color: #c7c7c7;
    padding: 0 5px;
}

.upload-section-controls {
    overflow: hidden;
}

.upload-section-controls .next-btn {
    width: 66px;
    /*font-size:17px;*/
    float: right;
    margin-left: 27px;
    /*font-weight:600;*/
}

.upload-section-total {
    text-align: right;
    padding: 14px 0 30px;
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    overflow: hidden;
}

.upload-section-total .upload-section-total-title {
    float: right;
}

.upload-section-total .upload-section-add-new-order {
    float: left;
    color: #1abc9c;
    font-size: 22px;
    padding-left: 18px;
}

.upload-section-add-new-order a {
    color: #1abc9c;
}

.upload-section-total-title {
    text-align: right;
    font-size: 16px;
}

.upload-section-total-price {
    font-size: 22px;
    font-weight: 400;
}


/* rotten */


/* begin of basket/upload */

.upload-section-item {}

.upload-section-item-rotten {
    z-index: 10;
    border: 2px solid #ff0000;
    border-radius: 7px;
    padding: 17px 16px;
}

.upload-section-item-name {
    /*
	z-index:102;
	position:relative;
*/
}

.rotten-order {
    position: absolute;
    width: 1004px;
    height: 266px;
    border-radius: 7px;
    margin: -17px 0 0 -16px;
    z-index: 12;
    background: rgba(255, 255, 255, 0.90);
}

.rotten-order-text {
    padding: 20px 0 25px 229px;
    font-size: 14px;
    color: #ff0000;
    line-height: 26px;
}

.rotten-order-controls {
    overflow: hidden;
    padding-left: 228px;
}

.rotten-order-controls a {
    margin-right: 22px;
    width: 124px;
    float: left;
}


/* end of basket/upload */

.rotten-order-name {
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    margin-left: 227px;
    margin-top: 18px;
}

.rotten-order-name span {
    font-size: 14px;
}

.message-basket-is-empty {
    font-size: 14px;
}

.message-basket-is-empty a {
    color: #1abc9c;
}

.empty_basket_title {
    font-size: 22px;
    margin-bottom: 25px;
}


/******************************************************************************************************/


/******************************************************************************************************/


/******************************************************************************************************/


/******************************************************************************************************/


/******************************************************************************************************/

#popup-upload_layouts-window {
    max-width: 910px;
}

.popup-upload_layouts-content {
    padding: 30px 35px 30px;
}

.upload_layouts-form {
    border: 1px solid #a9a9a9;
    border-radius: 7px;
    overflow: hidden;
}

.upload_layouts-form-lay {
    background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
    position: absolute;
    z-index: 90;
    display: none;
    margin-top: -1px;
    margin-left: -1px;
}

.upload_layouts-form-top {
    background: #f3f3f3;
    padding: 17px 24px;
    overflow: hidden;
}

.upload_layouts-form-top-view {
    width: 100px;
    float: left;
}

.upload_layouts-form-top-view a {
    width: 96px;
    height: 33px;
    display: block;
    line-height: 33px;
    border: 2px solid #1abc9c;
    border-radius: 7px;
    color: #1abc9c;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background: #ffffff;
    text-decoration: none;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.upload_layouts-form-top-view a:hover {
    background: #1abc9c;
    color: #ffffff;
}

.upload_layouts-form-top-button {
    width: 109px;
    float: left;
}

.upload_layouts-form-top-button a {
    width: 105px;
    height: 33px;
    display: block;
    background: #ffffff;
    line-height: 33px;
    border: 2px solid #d5d5d5;
    border-radius: 7px;
    color: #d5d5d5;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}

.upload_layouts-form-top-button a.active {
    border: 2px solid #1abc9c;
    background: #1abc9c;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.upload_layouts-form-top-button a.active:hover {
    color: #1abc9c;
    background: #ffffff;
}

.upload_layouts-form-top-files {
    width: 625px;
    min-height: 32px;
    float: left;
    border: 1px solid #a2a2a2;
    border-radius: 7px;
    background: #ffffff;
    margin: 0 25px;
    padding: 0 3px 3px 0;
}

.upload_layouts-form-top-files-item {
    float: left;
    height: 30px;
    background: #1abc9c;
    border-radius: 5px;
    padding: 0 26px 0 14px;
    overflow: hidden;
    margin: 3px 0 0 3px;
}

.upload_layouts-form-top-files-item-delete {
    background: url("https://gmprint.ru/images/order/upload/upload-form-top-files-item-delete.png") no-repeat;
    height: 13px;
    width: 13px;
    cursor: pointer;
    float: left;
    margin-right: 16px;
    margin-top: 9px;
}

.upload_layouts-form-top-files-item-name {
    color: #ffffff;
    float: left;
    line-height: 30px;
    font-size: 14px;
}

.upload_layouts-form-list {
    /*margin-top: 16px;*/
}

.upload_layouts-form-list li {
    position: relative;
    /*border-top: 1px solid #e1e0e0;*/
    /*clear: both;*/
    float: left;
    width: 200px;
    border: 1px solid #adadad;
    padding-bottom: 2px;
    margin-bottom: 10px;
}

.upload_layouts-form-list li.active {
    border-bottom: 3px solid #22a2b5;
    padding-bottom: 0px;
}

.upload_layouts-form-list li.not-active {
    display: none;
}

#layouts_filelist {
    overflow: hidden;
    margin-bottom: 20px;
}

.with-files #layouts_filelist {
    display: block;
}

.upload_layouts-form-item-percent::after {
    content: '';
    position: absolute;
    /* ���������� ���������������� */
    left: 17px;
    bottom: -11px;
    /* ��������� ������������ */
    border: 6px solid transparent;
    /* ���������� ������� */
    border-top: 5px solid #1abc9c;
    /* ��������� ����������� */
}

.upload_layouts-form-item-percent {
    position: absolute;
    width: 46px;
    text-align: center;
    left: 0px;
    font-size: 14px;
    font-weight: 600;
    height: 24px;
    line-height: 24px;
    z-index: 10;
    display: block;
    border-radius: 3px;
    top: -32px;
    background: #1abc9c;
    color: #ffffff;
    -moz-transition: left 300ms linear;
    -webkit-transition: left 300ms linear;
    -o-transition: left 300ms linear;
    transition: left 300ms linear;
}

.upload_layouts-form-item-status .checked {
    margin: 3px 0 0;
    width: 14px;
    height: 11px;
    background: url("https://gmprint.ru/images/upload_layouts/checked.png") no-repeat;
}

.upload_layouts-form-item-status .chained {
    margin: -3px 5px 0;
    width: 16px;
    height: 16px;
    background: url("https://gmprint.ru/images/upload_layouts/chained.png") no-repeat;
}

.upload_layouts-form-item-status .loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid rgba(26, 188, 156, 1);
    border-right: 3px solid rgba(26, 188, 156, 1);
    border-bottom: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    -webkit-animation: load8 2.1s infinite linear;
    animation: load8 2.1s infinite linear;
}

.upload_layouts-form-item-status .loader,
.upload_layouts-form-item-status .loader:after {
    border-radius: 50%;
    width: 11px;
    height: 11px;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.upload_layouts-form-item-status {
    width: 30px;
    height: 36px;
    padding-top: 18px;
    float: left;
    z-index: 10;
    position: relative;
    display: none;
}

.upload_layouts-form-item-name {
    /*padding-left: 0;*/
    /*width: 660px;*/
    /*font-size: 14px;*/
    /*height: 54px;*/
    /*line-height: 54px;*/
    /*float: left;*/
    /*z-index: 10;*/
    /*position: relative;*/
    /*overflow: hidden;*/
    padding: 6px 10px;
    font-size: 0.9em;
    color: #808080;
}

.upload_layouts-form-item-size {
    width: 100px;
    float: left;
    font-size: 14px;
    height: 54px;
    line-height: 54px;
    text-align: right;
    z-index: 10;
    position: relative;
}

.upload_layouts-form-item-delete,
.upload_layouts-form-item-orders-delete {
    /*width: 15px;*/
    float: right;
    margin: 0;
    /*z-index: 10;*/
    /*position: relative;*/
}

.upload_layouts-form-item-delete a,
.upload_layouts-form-item-orders-delete a {
    height: 15px;
    width: 15px;
    margin: -24px 6px 0 0;
    display: block;
    float: right;
    text-decoration: none;
}

.upload_layouts-form-item-line {
    border: 1px solid #22a2b5;
    border-radius: 4px;
    width: 804px;
    margin: 0 auto;
    padding: 1px;
}

.upload_layouts-form-item-line span {
    height: 27px;
    border-radius: 3px 0 0 3px;
    background: url(/img/progress_bar.jpg) repeat-x;
    display: block;
    z-index: 1;
    -moz-transition: width 300ms linear;
    -webkit-transition: width 300ms linear;
    -o-transition: width 300ms linear;
    transition: width 300ms linear;
}

.popup-upload_layouts-bottom {
    /*display: none;*/
    overflow: hidden;
    clear: both;
}

.popup-upload_layouts-bottom.active {
    display: block;
}

.popup-upload_layouts-comment {
    margin: 0 0 25px;
    padding-top: 15px;
    border-top: 1px solid #e1e0e0;
}

.popup-upload_layouts-comment .text {
    margin-bottom: 10px;
}

.popup-upload_layouts-comment textarea {
    width: 784px;
    height: 100px;
}

.popup-upload_layouts-attention {
    font-size: 14px;
    margin: 18px 0 34px;
    background: url("https://gmprint.ru/images/info.png") no-repeat;
    padding-left: 30px;
}

.popup-upload_layouts-attention a {
    color: #1abc9c;
    font-weight: 600;
}

.popup-upload_layouts-req {
    overflow: hidden;
    margin-bottom: 25px;
}

.popup-upload_layouts-req-checkbox {
    float: left;
    width: 21px;
}

.popup-upload_layouts-req-checkbox a {
    width: 17px;
    height: 17px;
    display: block;
    border: 2px solid #1abc9c;
    border-radius: 5px;
}

.popup-upload_layouts-req-checkbox a.active {
    background: url("https://gmprint.ru/images/order/upload/upload-req-checkbox.png") center center no-repeat;
}

.popup-upload_layouts-req-checkbox a.active-disabled {
    background: url("https://gmprint.ru/images/order/upload/upload-req-checkbox.png") center center no-repeat;
}

.popup-upload_layouts-req-text {
    float: left;
    margin-left: 10px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
}

.popup-upload_layouts-req-text a {
    color: #1abc9c;
}

.popup-upload_layouts-controls {
    width: 360px;
}

.popup-upload_layouts-controls a.correction-btn {
    width: 240px;
    float: right;
}

.popup-upload_layouts-controls a.save-btn {
    width: 85px;
    float: left;
    margin-right: 20px;
}

.popup-upload_layouts-controls a.cancel-btn {
    width: 75px;
    float: left;
    margin-right: 20px;
}

.new-upload_layouts-form-top {
    /*border: 1px dashed #1abc9c;*/
    /*background: #f5fffd;*/
    border-radius: 7px;
    width: 818px;
    height: 170px;
    padding: 1px;
    margin: 0 auto;
}

.new-upload_layouts-form-text {
    margin: 73px 0 0 0;
}


/**********Errors container *************/

#errorsContainer {
    margin-top: 40px;
}

#errorsContainer .message_error {
    margin-bottom: 16px;
}

#errorsContainer .message_error .message {
    line-height: 15px;
    padding: 8px 17px 8px 48px;
}

#errorsContainer .closeIcon {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
}

#errorsContainer .closeIcon i {
    color: #de1500;
    font-size: 16px;
}


/***************************************/

.new-upload_layouts-form-text-image {
    margin: 28px auto 0;
    width: 52px;
    cursor: pointer;
    height: 64px;
}

.new-upload_layouts-form-subtitle {
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: #808080;
}

.new-upload_layouts-form-subtitle span {
    cursor: pointer;
    font-size: 22px;
}

.new-upload_layouts-form-subtitle span:hover {
    text-decoration: none;
}

#drop-target.dragover {
    /*background: #e8f8f5;*/
}

#drop-target {
    cursor: pointer;
}

.new-upload_layouts-form-top .st0 {
    fill: #555555;
}

.new-upload_layouts-form-top.active .st0 {
    fill: #1abc9c;
}

.new-upload_layouts-form-top.active {
    border: 2px solid #1abc9c;
    background: #eafffb;
    color: #1abc9c;
    padding: 0;
}

.upload_layout-add-link-wrapper {
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 40px;
}

.upload_layout-add-link-wrapper .text {
    font-size: 14px;
    margin-bottom: 10px;
}

.upload_layout-add-link-wrapper .field {}

.upload_layout-add-link-wrapper .field input {
    margin: 0;
    padding: 0 0 0 23px;
    width: 675px;
    float: left;
    border: 2px solid #9c9c9c;
    border-radius: 6px 0 0 6px;
    height: 32px;
    line-height: 32px;
    border-right: 0;
}

.upload_layout-add-link-wrapper .field input:focus {
    border-color: #1abc9c;
}

.upload_layout-add-link-wrapper .btn {
    width: 120px;
    float: left;
}

.upload_layout-add-link-wrapper .btn a {
    display: block;
    text-align: center;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    color: #ffffff;
    background: #1abc9c;
    height: 36px;
    line-height: 36px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
}

.upload_layout-add-link-wrapper .btn a:hover {
    background-color: #25deb3;
}

.upload_layout-warning {
    background: #f26363;
    padding: 17px 0;
    color: #ffffff;
    position: relative;
    display: none;
    font-size: 14px;
}

.upload_layout-warning .text {
    padding: 0 50px 0 30px;
    line-height: 18px;
}

.upload_layout-warning a {
    color: #ffffff;
}

.upload_layout-warning .close {
    position: absolute;
    left: 795px;
    font-size: 20px;
    width: 25px;
    cursor: pointer;
    line-height: 20px;
}

.file-uploading {
    color: #000000;
}

file-uploading {
    color: #000000;
}

.upload_layouts-form-list li:hover {
    border-bottom: 3px solid #22a2b5;
    padding-bottom: 0px;
}

#btnbtnupload:disabled {
    color: #a2a2a2;
    border: 2px solid #a2a2a2;
}

#btnbtnupload:disabled i {
    color: #a2a2a2;
}