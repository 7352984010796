    html {
        position: relative;
        min-height: 100%;
    }
    
    body {
        margin-bottom: 290px;
        /* значение высоты #footer */
        /*margin-bottom: 391px;*/
        font-family: 'Open Sans', sans-serif;
    }
    
    body.lightgrey {
        background-color: #f6f5f3;
    }
    
    a {
        color: #555555;
        text-decoration: underline;
    }
    
    a:hover {
        color: #de1500;
    }
    
    a.nohover:hover {
        color: #555555;
        text-decoration: underline;
    }
    
    ul {
        list-style-type: none;
        margin-left: 0;
        /* Отступ слева в браузере IE и Opera */
        padding-left: 0;
        /* Отступ слева в браузере Firefox, Safari, Chrome */
    }
    
    .checkbox input[type="checkbox"]:focus+label::before,
    .checkbox input[type="radio"]:focus+label::before {
        outline: none;
    }
    
    .border-right {
        border-right: 2px solid lightgray;
    }
    
    .padding {
        padding-top: 12px;
    }
    
    .padding-top-1 {
        padding-top: 8px;
    }
    
    .padding-top-2 {
        padding-top: 16px;
    }
    
    .padding-top-3 {
        padding-top: 24px;
    }
    
    .padding-bottom-1 {
        padding-bottom: 8px;
    }
    
    .padding-bottom-2 {
        padding-bottom: 16px;
    }
    
    .padding-bottom-3 {
        padding-bottom: 24px;
    }
    
    .padding-left-0 {
        padding-left: 0 !important;
    }
    
    .padding-left-minus-3 {
        padding-left: -24px !important;
    }
    
    .margin-top-1 {
        margin-top: 8px;
    }
    
    .margin-top-2 {
        margin-top: 24px;
    }
    
    .margin-top-3 {
        margin-top: 27px;
    }
    
    .margin-top-4 {
        margin-top: 32px;
    }
    
    .margin-bottom-0 {
        margin-bottom: 3px;
    }
    
    .margin-bottom-1 {
        margin-bottom: 8px;
    }
    
    .margin-bottom-2 {
        margin-bottom: 16px;
    }
    
    .margin-bottom-3 {
        margin-bottom: 28px;
    }
    
    .margin-bottom-4 {
        margin-bottom: 32px;
    }

    .margin-bottom-5 {
        margin-bottom: 64px;
    }

    .margin-left-minus-2,
    .margin-left-0 {
        margin-left: -15px;
    }
    
    .margin-left-1 {
        margin-left: 8px;
    }
    
    .margin-left-2 {
        margin-left: 16px;
    }
    
    .margin-left-3 {
        margin-left: 24px;
    }
    
    .margin-left-4 {
        margin-left: 32px;
    }
    
    .margin-left-minus-1 {
        margin-left: -8px;
    }
    
    .margin-right-minus-1 {
        margin-right: -8px;
    }
    
    .margin-right-1 {
        margin-right: 8px;
    }
    
    .margin-right-2 {
        margin-right: 16px;
    }
    
    .margin-right-3 {
        margin-right: 24px;
    }
    
    .margin-right-4 {
        margin-right: 32px;
    }
    
    .vcenter {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
    
    .vertical-align {
        /*margin-top: 0.45em;*/
        /*margin-bottom: 0.45em;*/
        /*vertical-align: 20%;*/
        padding: 1em 2em 0;
    }
    /* Формы */
    
    .forms-elementitem {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    
    .forms-elementitem:focus {
        border: 1px solid #22a2b5 !important;
        border-radius: 1px;
    }
    .container .authorization {
        padding: 0;
    }
    .container .authorization #social_link {
        margin-top: 24px;
    }
    
    .form-auth {
        /*max-width: 566px;*/
        max-width: 574px;
    }
    
    .form-auth h2, .container .authorization h2 {
        font-weight: 300;
        font-size: 36px;
        color: #010101;
        line-height: 1.1;
        margin: 33px 0 22px 0;
    }
    
    .form-auth p {
        margin-bottom: 24px;
    }
    
    .form-auth hr {
        margin-top: 24px;
        margin-bottom: 15px;
    }
    
    .form-auth .info {
        margin-bottom: 20px;
    }
    
    .form-auth .btn-lightseagreen {
        /*width: 213px;*/
    }
    
    .form-auth input {
        font-size: 13px !important;
        border-radius: 3px !important;
        border: 1px solid #dadada;
        box-shadow: none;
    }
    
    .form-auth input::-webkit-input-placeholder {
        color: #303030;
        font-weight: bold;
    }
    
    .form-auth input::-moz-placeholder {
        color: #303030;
        font-weight: bold;
    }
    /* Firefox 19+ */
    
    .form-auth input:-moz-placeholder {
        color: #303030;
        font-weight: bold;
    }
    /* Firefox 18- */
    
    .form-auth input:-ms-input-placeholder {
        color: #303030;
        font-weight: bold;
    }
    
    .form-auth .message_error.message_error-1 {
        font-size: 13px;
        right: -284px;
        top: 166px;
        position: absolute;
    }
    
    .form-auth .message_error.message_error-2 {
        font-size: 13px;
        right: -284px;
        top: 236px;
        position: absolute;
    }
    
    .form-auth.margin-bottom {
        margin-bottom: 87px;
    }
    /* Ошибка в форме, под input*/
    
    .has-error .forms-elementitem,
    .has-error .forms-elementitem:focus {
        border-color: #de1500 !important;
        border-bottom-width: 0 !important;
        box-shadow: none;
    }
    
    .has-error .error-block {
        height: 32px;
        background: #de1500;
        color: #ffffff;
        border-radius: 0 0 3px 3px;
        padding: 6px 0 5px 0;
        margin-top: -2px;
    }
    
    .has-error .error-block .error-text {
        background: #de1500;
        color: #ffffff;
        font-size: 13px;
        top: -3px;
        position: relative;
    }
    
    .has-error .error-block .icon-warning {
        font-size: 18px;
        color: #ffa298;
        padding: 0 8px 0 14px;
    }
    /* Модальное окно вход */
    
    #authmodal .has-error .error-block {
        height: inherit;
        background: #de1500;
        color: #ffffff;
        border-radius: 0 0 3px 3px;
        padding: 6px 0 3px 10px;
        margin-top: -2px;
    }
    
    #authmodal .has-error .error-block .icon-warning {
        padding: 0 8px 0 1px;
    }
    /* Ошибка в форме, под input*/
    
    .form-auth .form-group {
        margin-bottom: 24px;
    }
    
    .form-auth a {
        padding: 16px 0;
    }
    
    .FlowupLabels .fl_input {
        padding: 20px 0 0 13px;
        border: 1px solid #dadada;
    }
    
    .FlowupLabels .fl_wrap {
        width: 100%;
        height: 48px;
        margin-bottom: 0;
    }
    
    .FlowupLabels .fl_wrap.focused .fl_label {
        color: #a2a2a2;
        top: 6px;
    }
    
    .FlowupLabels .fl_label {
        font-size: 13px;
        color: #555555;
        left: 14px;
    }
    
    .FlowupLabels .fl_wrap.focused .fl_label,
    .FlowupLabels .fl_wrap.populated .fl_label,
    .no-js .FlowupLabels .fl_label {
        top: 6px;
        color: #a2a2a2;
    }
    /* Формы */
    /* Страница авторизации (вход) */
    
    .row-middleorization.modal-header {
        padding-top: 34px;
        padding-bottom: 5px;
    }
    
    .row-middleorization .modal-title {
        font-size: 36px;
        font-weight: 300;
        color: #010101;
        line-height: 1;
    }
    
    .row-middleorization.modal-body {
        padding-left: 0;
        padding-right: 0;
        /*margin-bottom: 87px;*/
    }
    
    .row-middleorization.modal-body p {
        margin-bottom: 15px;
    }
    /* Страница авторизации (вход) */
    /* Модальные окна */
    
    .modal-header {
        border: none;
    }
    
    .modal-open {
        /*background-color: rgba(0,0,0,0.9);*/
    }
    
    .modal-open>div {
        /*background-color: rgba(0,0,0,0.7);*/
    }
    
    .modal-open .modal {
        /*background-color: rgba(0,0,0,0.7); !* Black w/ opacity *!*/
    }
    
    .modal-open header {
        border-bottom: none;
    }
    /* Модальные окна */
    /* Модальное окно modal_view */
    
    #modal_view .modal-body {
        position: inherit;
    }
    
    #modal_view .modal-body #back-photo,
    #modal_view .modal-body #back-map {
        min-height: 0 !important;
    }
    
    .modal-body #back-map {
        display: none !important;
    }
    
    #modal_view.modal {
        /*top: 122px;*/
        padding-top: 136px;
        background-color: rgba(0, 0, 0, 0.7);
    }
    
    #modal_view .modal-header {
        border-bottom: none;
        overflow: hidden;
        text-align: center;
    }
    
    #modal_view .modal-header .close {
        opacity: 1;
        font-size: 23px;
        position: relative;
        top: 3px;
        right: -15px;
    }
    
    #modal_view .modal-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 30px;
        padding-left: 7px;
        margin-top: 9px;
        text-align: left;
    }
    
    #modal_view .modal-dialog {
        width: 904px;
        height: auto;
    }
    
    #modal_view .modal-footer {
        border: none;
    }
    
    #modal_view .modal-footer .button-group {
        margin: 25px 24px 23px 24px;
    }
    
    #modal_view .modal-footer .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
    }
    
    #modal_view .modal-footer .checkbox {
        text-align: center;
        padding-left: 0;
    }
    
    #modal_view .modal-body {
        overflow: hidden;
        padding: 13px 24px 9px 24px;
    }
    
    #modal_view .modal-body .bcard img.vertical,
    .order .bcard img.vertical {
        box-shadow: -4px 4px 20px 5px #c6c9ca;
        height: 400px !important;
        min-height: 400px !important;
        /*min-width: 228px;*/
        /*width: inherit!important;*/
    }
    
    #modal_view .modal-body .bcard img.horizontal,
    .order .bcard img.horizontal {
        box-shadow: -4px 4px 20px 5px #c6c9ca;
        width: 400px !important;
        min-width: 400px !important;
        /*min-height: 228px;*/
        /*height: inherit!important;*/
    }
    
    #modal_view .modal-body .bcard img {
        background: #ffffff;
        /* box-shadow: -4px 4px 20px 5px #c6c9ca;
           max-height: 216px;*/
    }
    
    #modal_view .modal-body .preview {
        display: inline-block;
        border: 1px solid #d3d3d3;
        margin: 21px 24px 0 0;
        position: relative;
    }
    
    #modal_view .modal-body .horizontal.preview:nth-child(5n+5) {
        margin-right: 0;
    }
    
    #modal_view .modal-body .vertical.preview:nth-child(8n+8) {
        margin-right: 0;
    }
    
    .upload_layouts-form-item.preview.horizontal,
    #modal_view .modal-body .preview .horizontal {
        width: 140px;
        /*margin-bottom: -1px;*/
    }
    
    .img-preview.insertImage,
    .img-preview {
        background-color: #F6F5F3;
        background-size: contain;
        background-position: center;
        width: 198px;
        height: 150px;
    }
    
    .upload_layouts-form-item.preview.vertical,
    #modal_view .modal-body .preview .vertical {
        width: 76px;
        height: 140px;
    }
    
    #modal_view .modal-body #back-photo {
        /*margin-top: 56px;*/
    }
    
    #modal_view .modal-body .preview img {
        margin: auto;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
    }
    
    #modal_view .modal-body .preview:hover:after,
    #modal_view .modal-body .preview.active:after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: #22a2b5;
        position: absolute;
        display: inline-block;
        left: 0;
        bottom: -5px;
    }
    
    #modal_view .modal-body .button-group {
        margin-top: 32px;
        margin-bottom: 11px;
    }
    /*#modal_view .modal-body .button-group .hr-vertical,*/
    
    #modal_view .modal-body .button-group hr {
        width: 1px;
        height: 32px;
        background-color: #aeaeae;
        display: inline-block;
        margin: 0 24px -12px 15px;
    }
    
    #modal_view .modal-body .button-group a {
        color: #22a2b5;
    }
    
    #modal_view .modal-body .button-group a:hover {
        color: #de1500;
    }

    #modal_view .modal-body .button-group .btn-lightseagreen {
        margin: 0;
    }

    #modal_view .modal-body .btn-group {
        width: 330px;
        margin-top: 31px;
        display: none;
    }
    
    .btn-group button {
        background: none !important;
        border: none !important;
        font-size: 13px;
        color: #555555;
        padding: 0 0 2px 0;
        margin-right: 26px;
    }
    
    .btn-group button.active {
        background: none !important;
        border: none !important;
        border-bottom: 3px solid #22a2b5 !important;
        margin-bottom: -3px;
        color: #555555 !important;
        border-radius: 0;
        width: auto;
        height: auto;
    }
    
    #modal_view .modal-body .btn-group.disabled {
        background: none !important;
    }
    
    #modal_view .modal-body .switch {
        /*margin-right: 68px;*/
        position: absolute;
        right: 80px;
        top: 33px;
    }
    
    #modal_view .modal-body .switch .text {
        right: 14px;
        left: 46px;
    }
    
    #modal_view .btn-heart {
        width: 40px;
        height: 40px;
        background: transparent;
    }
    
    #modal_view .btn-heart.btn:active,
    #modal_view .btn-heart.btn.active {
        box-shadow: none;
    }
    
    #modal_view .icon-heart-o,
    #modal_view .icon-heart {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.77;
        color: #555555;
    }
    
    #modal_view .icon-heart {
        color: #dc143c;
    }
    
    #modal_view .modal-footer {}
    
    #modal_view .modal-footer .btn-lightseagreen {
        width: 151px;
        height: 40px;
        border-radius: 20px;
        padding: 9px 16px 12px 23px;
    }
    
    #modal_view .modal-footer .btn-lightseagreen span {
        margin-right: 4px;
    }
    
    #modal_view .modal-footer .btn-lightseagreen i {
        font-weight: bold;
        font-size: 15px;
    }
    
    #modal_view .modal-footer .switch {
        padding-top: 11px;
    }
    /* Модальное окно modal_view */
    /* Модальное окно preview */
    
    #modal_preview.modal {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        overflow-x: auto;
    }
    
    #modal_preview .modal-header {
        border-bottom: none;
    }
    
    #modal_preview .modal-header h2 {
        margin-top: 40px;
        font-size: 36px;
    }
    
    #modal_preview .modal-header .close {
        color: #f5f5f5;
        font-size: 30px;
        line-height: 0.6;
        font-weight: normal;
        margin-right: 54px;
        margin-top: 10px;
        opacity: 0.4;
        outline: none;
        position: absolute;
        top: 54px;
        right: 60px;
    }
    
    #modal_preview .modal-header .close .icon-close_popup {
        font-size: 33px;
        font-weight: normal;
    }
    
    #modal_preview .modal-header .modal-title {
        text-align: center;
        color: #dadada;
    }
    
    #modal_preview .modal-dialog {
        min-width: 1052px;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #353c41;
    }
    
    #modal_preview .modal-content {
        /*height: 100%;*/
        min-width: 1052px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /*position: fixed;*/
        background-color: #353c41;
        box-shadow: none;
        border: none;
    }
    
    #modal_preview .modal-body {
        color: #989898;
        margin-top: 16px;
    }
    
    #modal_preview .modal-body .bcard-block {
        display: table;
        height: 800px;
    }
    
    #modal_preview .modal-body .bcard-block .bcard-wrapper {
        vertical-align: middle;
        display: table-cell;
    }
    /*#modal_preview .modal-body #bcard:first-child 
    Teamviewer ID: 406 186 733
password: 3274
    {*/
    /*!*margin-right: 24px;*!*/
    /*}*/
    /*#modal_preview .modal-body #bcard:last-child {*/
    /*}*/
    
    #modal_preview .modal-body #bcard {}
    
    #modal_preview .modal-body #bcard.vertical {
        height: 80%;
        box-shadow: 0 10px 30px 12px rgba(35, 37, 38, 0.5);
    }
    
    #modal_preview .modal-body #bcard.horizontal {
        width: 100%;
    }
    
    #modal_preview .modal-body .button-square {
        background: transparent;
        margin-right: 53px;
        margin-top: 0;
        border: 1px solid #dadada;
    }
    
    #modal_preview .modal-body .button-square i {
        color: #dadada;
        font-size: 1.3em;
    }
    
    #modal_preview .modal-footer .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
    }
    
    #modal_preview .modal-footer .checkbox {
        text-align: center;
    }
    
    #modal_preview img.preview {
        margin-top: 30px;
        width: 100%;
        /*border-radius: 6px;*/
    }
    
    #modal_preview img.preview.active {
        outline: 4px solid #22a2b5;
        outline-offset: -4px;
    }
    
    #modal_preview img.preview.radius {
        border-radius: 6px;
    }
    
    #modal_preview .modal-body .btn-group {
        margin: 30px 30%;
    }
    
    #modal_preview .modal-body .btn-group button {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    
    #modal_preview .modal-body .btn-group button.active {
        background: #22a2b5;
        color: #ffffff;
    }
    
    #modal_preview .modal-body .btn-group-circle-horizontal {
        /*position: fixed;*/
        /*bottom: 62px;*/
        /*left: 45.8%;*/
    }
    
    #modal_preview .modal-footer {
        padding: 40px;
        border: none;
    }
    /* Модальное окно preview */
    /* Модальное окно upload images */
    
    #imageEditModal {
        padding-top: 130px;
    }
    
    #imageEditModal .modal-header {
        padding: 0;
        border-bottom: none;
    }
    
    #imageEditModal .modal-header .text {
        font-size: 14px;
        color: #9d9d9d;
    }
    
    #imageEditModal .modal-header .upload_layouts-form-item-name {
        height: 30px;
        padding: 6px 0 11px 11px;
    }
    
    #imageEditModal .modal-header a {
        margin: -24px 8px;
        text-decoration: none;
    }
    
    #imageEditModal .modal-header a>.icon-trash {
        font-size: 14px;
        color: #555555;
    }
    
    #imageEditModal .modal-content {
        padding: 16px 36px 0px 36px;
    }
    
    .infoupload {
        text-align: center;
        padding: 0 20px;
        cursor: default;
        margin-bottom: 40px;
        color: #9d9d9d;
    }
    
    .text-loader {
        padding-top: 10px;
    }
    
    #imageEditModal .preview {
        width: 200px;
        height: 180px;
        margin-right: 8px;
        padding-bottom: 20px;
    }
    
    #imageEditModal .preview:nth-child(4n+4) {
        margin-right: 0px;
    }
    
    #imageEditModal .preview img {
        width: 100%;
        /* kak
    width:  131px;
    height: 76px;
    */
    }
    
    #imageEditModal .btn-lightseagreen {
        border-radius: 20px;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        width: 124px;
        /* margin-right: 40px; */
        background: #22a2b5 !important;
        color: #ffffff !important;
    }
    
    #imageEditModal .btn-group {
        margin: 0 40px 33px 0;
        min-height: 30px;
        clear: both;
        float: right;
    }
    
    #imageEditModal .btn-group button {
        margin-top: 16px;
        margin-left: 24px;
        margin-right: 0;
    }
    
    #imageEditModal .btn-default {
        border-radius: 20px;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        background: transparent;
        color: #555555;
        border: 1px solid #a9a9a9 !important;
    }
    
    #imageEditModal .btn-default:hover {
        width: inherit;
        color: #555555 !important;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
    }
    
    #imageEditModal .btn-upload {
        width: 280px;
        height: 48px;
        margin-bottom: 27px;
    }
    
    #imageEditModal .btn-upload>img {
        margin-top: -6px;
        margin-right: 6px;
    }
    
    #imageEditModal .close {
        font-weight: bold;
        font-size: 23px;
        color: #555555;
        opacity: 1;
        outline: none;
        z-index: 100;
        position: absolute;
        right: 16px;
        top: 16px;
    }
    
    #imageEditModal hr {
        width: 93.5%;
        margin: -4px 0 32px 21px;
        color: #d3d3d3;
        border-top: 1px solid #eee;
    }
    
    #imageEditModal .upload_info {
        width: 100%;
        padding-top: 8px;
    }
    
    #imageEditModal .upload_layouts-form-item-line {
        border: 1px solid #22a2b5;
        width: 100%;
        height: 31px;
        padding: 1px 1px 0 1px;
        overflow: hidden;
    }
    /* Модальное окно upload authmodal */
    
    #authmodal .modal-content {
        width: 420px;
        /*height: 498px;*/
    }
    
    #authmodal.modal {
        /*top: 138px;*/
        padding-top: 138px;
        /* background-color: rgba(255, 255, 255, .9); */
    }
    
    #authmodal .modal-dialog {
        width: 412px;
    }
    
    #authmodal .modal-header {
        padding-top: 22px;
        margin-bottom: 0;
        border: none;
        padding-bottom: 0;
    }
    
    #authmodal .modal-header .close {
        color: #000000;
        font-size: 22px;
        /*line-height: 0.6;*/
        font-weight: normal;
        opacity: 1;
        outline: none;
        z-index: 100;
    }
    
    #authmodal .modal-header .modal-title {
        font-size: 30px;
        font-weight: lighter;
    }
    
    #authmodal .modal-body {
        padding: 0 40px;
    }
    
    #authmodal .modal-footer {
        border: none;
    }
    
    #authmodal p {
        margin-top: 19px;
        margin-bottom: 16px;
    }
    
    #authmodal .checkbox label {
        /*margin-right: 14px;*/
    }
    
    #authmodal form {
        margin-bottom: 16px;
    }
    
    #authmodal input {
        font-size: 13px;
    }
    
    #authmodal input::-webkit-input-placeholder {
        color: #303030;
        font-weight: bold;
    }
    
    #authmodal input::-moz-placeholder {
        color: #303030;
        font-weight: bold;
    }
    /* Firefox 19+ */
    
    #authmodal input:-moz-placeholder {
        color: #303030;
        font-weight: bold;
    }
    /* Firefox 18- */
    
    #authmodal input:-ms-input-placeholder {
        color: #303030;
        font-weight: bold;
    }
    
    #authmodal .form-group {
        margin-bottom: 24px;
    }
    
    #authmodal .checkbox-category {
        margin-top: -6px;
        margin-bottom: 23px;
    }
    
    #authmodal .btn-link {
        font-size: 15px;
        margin: 0;
        padding: 8px 0;
        color: #22a2b5;
        opacity: 1;
        text-decoration: underline;
    }
    
    #authmodal .btn-link:hover {
        color: #de1500;
    }
    
    #authmodal .padding {
        padding-left: 50px;
        padding-right: 50px;
    }
    
    #authmodal .errors,
    #authmodal #errors {
        font-weight: bold;
        color: red;
    }
    /* Модальное окно upload authmodal */
    /* Модальное окно modal_share */
    
    .modal_share {}
    
    .modal_share.modal {}
    
    .modal_share .modal-dialog {
        width: 600px;
    }
    
    .modal_share .modal-title {
        color: #000000;
        text-align: center;
    }
    
    .modal_share .modal-body .form-group input::-webkit-input-placeholder,
    .modal_share .modal-body .form-group input::-moz-placeholder,
    .modal_share .modal-body .form-group input:-moz-placeholder,
    .modal_share .modal-body .form-group input:-ms-input-placeholder {
        color: #9d9d9d;
    }
    
    .modal_share .modal-content {
        margin-top: 236px;
        color: #555555;
    }
    
    .modal_share .modal-body,
    .modal_share .modal-body .col-xs-10 {
        padding-left: 0;
        padding-right: 0;
    }
    
    .modal_share .modal-body .btn-rectangle {
        width: 116px;
        margin-top: -35px;
        border: 1px solid #a2a2a2;
        background-color: #ffffff;
        float: right;
    }
    
    .modal_share .modal-body .forms-elementitem {
        height: 36px;
        border-radius: 3px;
    }
    
    .modal_share .modal-body input[readonly] {
        background-color: #ffffff;
        cursor: text;
        width: 73%;
    }
    
    .modal_share .modal-body label {
        font-weight: normal;
        margin-bottom: 11px;
    }
    
    .modal_share .modal-body label[for=description] {
        margin-top: 6px;
    }
    
    .modal_share .modal-body .form-group {
        margin-bottom: 13px;
    }
    
    .modal_share .modal-body textarea.forms-elementitem {
        height: 54px;
    }
    
    .modal_share .modal-body .btn-lightseagreen {
        margin-top: 11px;
    }
    
    .modal_share .modal-body .btn-lightseagreen i {
        font-size: 15px;
        margin-left: 8px;
    }
    
    .modal_share .modal-body #social_link {
        margin-top: 13px;
    }
    
    .modal_share .row:first-child {
        margin-bottom: 0;
    }
    
    .modal_share .modal-footer {
        border: none;
        padding-bottom: 0;
    }
    
    .modal_share .modal-header .close {
        opacity: 1;
        font-size: 23px;
        position: relative;
        top: 3px;
    }
    
    .form-auth input::-webkit-input-placeholder {
        color: #303030;
        font-weight: bold;
    }
    
    .form-auth input::-moz-placeholder {
        color: #303030;
        font-weight: bold;
    }
    /* Firefox 19+ */
    
    .form-auth input:-moz-placeholder {
        color: #303030;
        font-weight: bold;
    }
    /* Firefox 18- */
    
    .form-auth input:-ms-input-placeholder {
        color: #303030;
        font-weight: bold;
    }
    
    .modal_share .link .icon-link {
        position: absolute;
        bottom: 29px;
        color: #9d9d9d;
        font-size: 18px;
        top: 41px;
        left: 25px;
    }
    
    .modal_share .link .forms-elementitem {
        padding-left: 32px;
    }
    /* Модальное окно modal_share */
    /* Всплывающее окно modal_share_mini */
    
    .modal_share_mini {
        width: 278px;
        position: absolute;
        padding: 21px 22px 21px 23px;
        margin: 0 0 0 78px;
        border: 1px solid lightgrey;
        border-radius: 4px;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
        top: 164px;
        background: white;
        z-index: 1;
        display: none;
    }
    
    .modal_share_mini>div {
        margin: 0;
        background: white;
        color: #555555;
    }
    
    .modal_share_mini>#social_link {
        margin-top: 14px;
    }
    
    .modal_share_mini>#social_link a {
        width: 32px;
        height: 32px;
        padding: 5px 0 !important;
    }
    
    .modal_share_mini>#social_link a:last-child {
        margin-right: 0 !important;
    }
    
    .modal_share_mini>.link {
        margin-top: 18px;
    }
    
    .modal_share_mini>.link input {
        width: 100%;
        height: 36px;
        color: #9d9d9d;
        border: 1px solid #dadada;
        padding-left: 29px;
        font-size: 14px;
    }
    
    .modal_share_mini>.link .icon-link {
        position: absolute;
        bottom: 29px;
        color: #9d9d9d;
        font-size: 18px;
        left: 31px;
    }
    /* Всплывающее окно modal_share_mini */
    /* Модальное окно authform */
    
    #authform {
        margin-top: 24px;
    }
    
    #authform .form-group {
        margin-top: 24px;
    }
    
    .form-auth #social_link {
        margin-bottom: 104px;
    }
    
    .form-auth .icon-eye-slash,
    .form-auth .icon-eye,
    #authform .icon-eye,
    #authform .icon-eye-slash {
        font-size: 20px;
        color: #a4a4a4;
        position: absolute;
        right: 17px;
        top: 13px;
        z-index: 5;
        cursor: pointer;
    }
    
    #authform .checkbox-category label {
        /*margin-top: -12px;*/
    }
    
    .form-auth input,
    #authform input {
        height: 48px;
        font-size: 13px !important;
        border-radius: 3px !important;
        border: 1px solid #dadada;
        box-shadow: none;
    }
    
    .form-auth .forms-elementitem,
    #authform .forms-elementitem {
        outline: none;
        box-shadow: none;
        padding: 14px 0 0 13px;
        height: 48px;
    }
    
    #authform .checkbox #remember {
        border: 1px solid #a2a2a2;
        height: inherit;
    }
    
    #authform .checkbox a {
        padding-right: 0;
        padding-top: 0;
        float: right;
    }
    
    #authform .checkbox a:hover {
        color: #de1500;
    }
    
    #authform .login {
        margin-top: 19px;
    }
    
    #authform .login a {
        padding-right: 0;
    }
    
    #authform+hr {
        margin-top: 23px;
        margin-bottom: 19px;
    }
    
    #authform .btn-lightseagreen {
        width: 105px;
    }
    
    #authform .message_error {
        font-size: 13px;
        right: -286px;
        top: 54px;
        position: absolute;
    }
    
    #authform .has-error .forms-elementitem {
        border-color: #de1500;
        border-radius: 3px 3px 0 0;
        border-bottom-width: 0 !important;
        padding: 14px 0 0 13px;
    }
    
    #authform .message_error:after,
    #authform .message_error:before {
        content: normal;
    }
    /* Модальное окно authform */
    /* Модальное окно upload modal_save */
    
    #modal_save {}
    
    #modal_save .modal-dialog {
        width: 420px;
        height: 226.29px;
    }
    
    #modal_save .form-group {
        margin-bottom: 20px;
    }
    
    #modal_save .form-group label {
        font-size: 14px;
        font-weight: normal;
        color: #555555;
        margin-bottom: 10px;
    }
    
    #modal_save .form-group input {
        border-radius: 3px;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    
    #modal_save .forms-elementitem:focus {
        height: inherit;
    }
    
    #modal_save .modal-header,
    #modal_save .modal-footer {
        border: none;
    }
    
    #modal_save .modal-header .close {
        font-size: 14px;
        opacity: 1;
    }
    
    #modal_save .modal-body {
        padding-bottom: 33px;
    }
    
    #windowMessage .modal-content,
    #modal_save .modal-content {
        border: 1px solid #dadada;
        box-shadow: 0px 10px 20px 0px rgba(64, 80, 90, 0.4);
        top: 172px;
    }
    
    #modal_save .modal-footer {
        padding: 0;
    }
    /* Модальное окно upload modal_save */
    /* Модальное окно upload modal_view */
    
    #modal_view .modal-content {
        width: 904px;
        min-height: 545px;
        padding-bottom: 24px;
        border-radius: 5px;
        background-color: #f6f5f3;
    }
    /* Модальное окно upload modal_view */
    
    .login.margin-bottom {
        min-height: 89px;
    }
    /* Кнопки */
    
    button {
        outline: none !important;
    }
    
    .btn-default:active:hover,
    .btn-default.active:hover,
    .open>.dropdown-toggle.btn-default:hover,
    .btn-default:active:focus,
    .btn-default.active:focus,
    .open>.dropdown-toggle.btn-default:focus,
    .btn-default:active.focus,
    .btn-default.active.focus,
    .open>.dropdown-toggle.btn-default.focus {
        color: inherit;
        background-color: inherit;
        border-color: #ffffff;
    }
    
    .form-auth a.btn-lightseagreen,
    .btn-lightseagreen {
        padding: 8px 22px;
        border-radius: 20px;
        height: 40px;
        background: #22a2b5;
        color: #ffffff !important;
        outline: none !important;
        text-decoration: none;
    }
    .btn-outline-lightseagreen {
        padding: 8px 22px;
        border: 1px solid #22a2b5;
        border-radius: 20px;
        height: 40px;
        background: transparent;
        color: #22a2b5 !important;
        outline: none !important;
        margin-left: 32px;
    }

    
    .btn-lightseagreen.confirm {
        margin-top: 8px;
    }
    
    .btn-lightseagreen.left-block,
    .btn-lightseagreen.pull-right {
        margin-left: 17px;
        margin-top: 15px;
    }
    
    .btn-lightseagreen .text {
        margin-right: 10px;
    }
    
    .btn-lightseagreen:hover:not(.disabled):not(.btn-wait) {
        background-color: #1A7F8E;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
    }
    .btn-outline-lightseagreen:hover:not(.disabled):not(.btn-wait) {
        border-color: #de1500 !important;
        color: #de1500 !important;
    }

    .btn-lightseagreen-mini {
        border-radius: 20px;
        width: 150px;
        height: 34px;
        background: #ffffff;
        color: #ffffff !important;
        outline: none !important;
    }
    
    .btn-lightseagreen-mini i {
        padding-left: 8px;
    }
    
    .btn-transparent {
        border: 1px solid #808080;
        border-radius: 20px;
        height: 40px;
        background: transparent;
        outline: none !important;
    }
    
    .btn-group-circle-vertical {
        width: 26px;
        margin: 0 auto;
    }
    
    .btn-group-circle-horizontal {
        /*width: 26px;*/
        /*margin: 20px auto;*/
        /*display: inline;*/
        /*position: relative;*/
        /*top: 56px;*/
    }
    
    .btn-group-circle-horizontal .btn-circle {
        /*display: inline;*/
    }
    
    .btn-circle {
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 6px 0;
        margin-bottom: 15px;
        margin-left: 10px;
        font-size: 12px;
        line-height: 1.428571429;
        border-radius: 15px;
        border: 0;
        outline: none !important;
    }
    
    .btn-circle.black {
        background-color: #303030;
    }
    
    .btn-circle.gray {
        background-color: #696969;
    }
    
    .btn-circle.white {
        background-color: #f5f5f5;
    }
    
    .btn-circle.wood {
        background: url("../img/wood.jpg");
    }
    
    .btn-circle.active {
        border: 2px solid #ffffff;
    }
    
    .disabled,
    .btn-disabled {
        border: 1px solid #a2a2a2;
        /*border-radius: 20px;*/
        /*height: 40px;*/
        /*background: #a2a2a2!important;*/
        color: #e5e5e5 !important;
        opacity: 0.8;
    }
    
    .btn-upload {
        border: 2px solid #22a2b5;
        border-radius: 6px;
        height: 48px;
        background: transparent;
        color: #505050;
        padding: 11px 0;
    }
    
    .btn-upload i {
        color: #22a2b5;
        font-size: 22px !important;
        position: absolute;
        top: 11px;
        left: 18px;
    }
    
    .btn-upload span {
        font-size: 16px !important;
        padding-bottom: 2px;
        padding-left: 34px;
    }
    
    .btn-upload.mini {
        border: 1px solid #22a2b5;
        height: 27px;
        padding: 4px 14px;
        border-radius: 4px;
    }
    
    .btn-upload.mini span {
        font-size: 12px !important;
    }
    
    .btn-upload.mini i {
        font-size: 15px !important;
        margin-right: 8px;
    }
    
    .button-square {
        background: transparent;
        border: 1px solid #dadada;
        border-radius: 3px;
        padding: 7px;
        width: 32px;
        height: 32px;
    }
    
    .button-square:not(.disabled):not(.active):hover i {
        color: #000000;
    }
    
    .button-square>i {
        font-size: 16px;
        color: #555555;
        margin-right: 0;
    }
    
    .button-square .active {
        background: #D8D8D8;
    }
    
    .button-rectangle {
        width: 132px;
        height: 33px;
        border: 1px solid #BDBDBD;
        background: transparent;
    }
    
    .btn-social {
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 9px 0 !important;
        margin-right: 4px !important;
        font-size: 16px !important;
        line-height: 1.428571429;
        border-radius: 20px;
        border: 0;
        outline: none !important;
        background: #4682B4;
        color: #FFFFFF !important;
    }
    
    .btn-social-mini {
        width: 32px;
        height: 32px;
        padding: 5px 0 !important;
    }
    
    .btn-social a:hover {
        color: transparent !important;
    }
    
    .btn-social i {}
    
    .fa {
        text-decoration: none;
    }
    
    .btn-social.vk {
        background: #4c6c91;
    }
    
    .btn-social.fb {
        background: #3b5998;
    }
    
    .btn-social.twitter {
        background: #55acee;
    }
    
    .btn-social.google {
        background: #dc4e41;
    }
    
    .btn-social.fa-odnoklassniki {
        background: #d7772d;
    }
    
    .btn-social.fa-pinterest {
        background: #cb2027;
    }
    
    .btn-social.mail {
        background: #f89c0e;
        text-decoration: none;
    }
    
    .btn-social.yandex {
        background: #f00;
        text-decoration: none;
    }
    /********************************/
    
    .btn-social2 {
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 9px 0 !important;
        margin-right: 4px !important;
        font-size: 36px !important;
        border-radius: 20px;
        border: 0;
        outline: none !important;
        text-decoration: none!important;
    }
    
    .btn-social2.vk i:before {
        color: #4c6c91;
    }
    
    .btn-social2.fb i:before {
        color: #3b5998;
    }
    
    .btn-social2.twitter i:before {
        color: #55acee;
    }
    
    .btn-social2.google i:before {
        color: #dc4e41;
    }
    
    .btn-social2.fa-odnoklassniki i:before {
        color: #d7772d;
    }
    
    .btn-social2.fa-pinterest i:before {
        color: #cb2027;
    }
    
    .btn-social2.mail i:before {
        color: #f89c0e;
    }
    
    .btn-social2.yandex i:before {
        color: #f00;
    }
    /* Ссылки */
    
    .btn-link {
        color: #22a2b5;
    }
    
    .btn-link:hover {
        color: #de1500;
    }
    
    .btn-link.drop-down,
    .btn-link.drop-down:not(:hover) {
        color: #808080;
        text-decoration-style: dotted;
    }
    
    .btn-group button {
        padding-top: 2px;
        padding-bottom: 2px;
    }
    /*.btn-group button.active{*/
    /*background: #22a2b5!important;*/
    /*color: #ffffff!important;*/
    /*border: 1px solid #22a2b5!important;*/
    /*}*/
    /*button[title]:hover:after {
    content: attr(title);
    padding: 4px 8px;
    color: #333;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 20;
    white-space: nowrap;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eeeeee), color-stop(1, #cccccc));
    background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
}*/
    /* Информационные сообщения */
    /*.message_error {
    width: 280px;
    border-radius: 2px;
    border: 1px solid #dadada;
    float: right;
    background: #ffffff;
}
    .message_error .icon {
        width: 40px;
        float: left;
        padding-top:    15px;
        padding-bottom: 15px;
        border-radius:  2px 0 0 2px;
        text-align: center;
        background: #FF3300;
        color: #FA8072;
        font-size: 21px;
    }
    .message_error .message {
        padding: 10px 10px 10px 60px;
    }

    .message_error:after, .message_error:before {
        left: 100%;
        top:  25%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .message_error:after {
        border-color: rgba(136, 183, 213, 0);
        border-left-color: #ffffff;
        border-width: 8px;
        margin-top: -8px;
    }
    .message_error:before {
        border-color: rgba(194, 225, 245, 0);
        border-left-color: #dadada;
        border-width: 9px;
        margin-top:   -9px;
    }
*/
    
    .message_info {
        width: 246px;
        font-size: 12px;
        text-align: left;
        border-radius: 2px;
        border: 1px solid #ccc;
        background: #ffffff;
    }
    
    .message_info .icon {
        font-size: 19px;
        text-align: center;
        padding: 35px 10px;
        border-radius: 2px 0 0 2px;
        background: #22a2b5;
        color: #B0E0E6;
        float: left;
    }
    
    .message_info .message {
        padding: 10px 10px 10px 60px;
    }
    
    .message_info .message a {
        color: #22a2b5;
    }
    /* Информационные сообщения */
    
    #close,
    .close {
        cursor: pointer;
    }
    
    .container {
        width: 1240px;
        min-width: 328px;
        padding-left: 24px;
        padding-right: 24px;
    }
    /* Header */
    
    header {
        background: #ffffff;
    }
    
    header a,
    header a:link,
    header a:visited {
        font-size: 13px;
        color: #bcc0c3;
        margin-right: 24px;
        cursor: pointer;
        text-decoration: none;
    }
    
    header a:hover {
        color: #f2f2f2;
    }
    
    header a:last-child {
        margin-right: 0;
    }
    
    header .row-top {
        height: 40px;
        padding: 9px 0 9px 0;
        background-color: #353c41;
    }
    
    header .row-top>.container {
        background: #353c41;
    }
    
    header .row-top a {
        margin-right: 16px;
    }
    
    header .row-top a.active {
        background: #000000;
        text-decoration: none;
        padding: 4px 14px 4px 14px;
        margin-right: 10px;
        border: 14px;
        border-radius: 3px;
        font-size: 13px;
        color: #bcc0c3;
        cursor: pointer;
    }
    
    header .row-top .right-links {
        text-align: right;
    }
    
    header .row-top .right-links a:last-child {
        margin-right: 0px;
    }
    
    header .row-top .phone {
        color: #ffffff;
        font-weight: bold;
        margin-right: 19px;
        font-size: 16px;
    }
    
    header .row-top .city {
        color: #e5e5e5;
        text-decoration: none;
        border-bottom: 1px dotted #e5e5e5;
    }
    
    header .row-top .city:hover {
        color: #ffffff;
        border-bottom: 1px dotted #ffffff;
        cursor: pointer;
    }
    
    header .row-middle {
        padding: 20px 0 10px 0;
    }
    
    .header__city {
        line-height: 1.5;
        padding-left: 18px;
    }
    
    header .row-middle .header__logo {
        top: -8px;
    }
    
    header .row-middle .header__title {
        color: #909aa0;
        font-size: 13px;
    }
    
    header .row-middle .city_link {
        color: #22a2b5;
        font-size: 13px;
        cursor: pointer;
        border-bottom: 1px dotted;
    }

    header .row-middle .phone {
        color: #353c41;
        font-size: 16px;
        font-weight: 700;
    }

    header .row-middle .phone a {
        color: #353c41;
        font-size: 16px;
        font-weight: 700;
    }
    
    header .row-middle .button-circle {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #aeb7bc;
        color: #aeb7bc;
        font-size: 19px;
        background-color: transparent;
    }
    
    header .row-middle .button-circle:hover {
        color: #d01b2a;
    }
    
    header .row-middle .button-circle.name {
        border: none;
        color: #ffffff;
        font-size: 14px;
        background-color: #22a2b5;
    }
    
    header .row-middle .user-menu {
        content: "";
        width: 364px;
        /*height: 278px;*/
        background-color: #ffffff;
        text-align: left;
        line-height: 1;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 34px;
        border-radius: 4px;
        position: absolute;
        right: 16px;
        box-shadow: 0 3px 15px 0 rgba(56, 60, 62, 0.35);
        z-index: 1010;
        cursor: auto;
        display: none;
        right: 11px;
        top: 60px;
    }
    
    header .row-middle .user-menu .name {
        font-size: 18px;
        margin-bottom: 10px;
    }
    
    header .row-middle .user-menu .email {
        font-size: 13px;
        color: #9d9d9d;
        margin-bottom: 10px;
    }
    
    header .row-middle .user-menu:after {
        content: "";
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -18px;
        right: 10px;
    }
    
    header .row-middle .user-menu:before {
        content: "";
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom: 10px solid #e9e9e9;
        position: absolute;
        top: -20px;
        right: 10px;
    }
    
    header .row-middle .user-menu .button-profile, .button-admin {
        width: 103px;
        height: 28px;
        font-size: 13px;
        color: white;
        background-color: #22a2b5;
        border: none;
        border-radius: 3px;
        margin-bottom: 20px;
    }
    
    header .row-middle .user-menu .button-profile:hover, .button-admin:hover {
        background-color: #1a7f8e;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.35);
    }
    
    header .row-middle .order-design {
        font-size: 13px;
    }
    
    header .row-middle .order-design .order-title {
        width: 50%;
        color: #9d9d9d;
        margin-bottom: 16px;
        float: left;
    }
    
    header .row-middle .order-design .order {
        width: 150px;
        padding-right: 20px;
        margin-bottom: 23px;
        border-right: 1px solid #dadada;
        float: left;
        line-height: .5;
    }
    
    header .row-middle .order-design .order div {
        margin-bottom: 16px;
    }
    
    header .row-middle .order-design .order div:last-child {
        margin-bottom: 0;
    }
    
    header .row-middle .order-design .order div a {
        color: #555555;
        text-decoration: none;
    }
    
    header .row-middle .order-design .order div a:hover {
        color: #de1500;
    }
    
    header .row-middle .order-design .design-title {
        width: 48%;
        color: #9d9d9d;
        margin-bottom: 16px;
        float: right;
    }
    
    header .row-middle .order-design .design {
        width: 50%;
        padding-left: 5px;
        float: right;
        line-height: 0.5;
    }
    
    header .row-middle .order-design .design div {
        margin-bottom: 16px;
    }
    
    header .row-middle .order-design .design div:last-child {
        margin-bottom: 0;
    }
    
    header .row-middle .order-design .design div a {
        color: #555555;
        text-decoration: none;
    }
    
    header .row-middle .order-design .design div a:hover {
        color: #de1500;
    }
    
    header .row-middle .exit {
        color: #9d9d9d;
        clear: both;
    }
    
    header .row-middle .exit a {
        color: #9d9d9d;
        text-decoration: none;
    }
    
    header .menu {
        border-bottom: 1px solid #dadada;
        border-top: 1px solid #dadada;
        height: 48px;
        padding-top: 13px;
    }
    
    header .menu .right-menu li {
        display: inline-block;
        margin-right: 20px;
    }
    
    header .menu .right-menu li a.active {
        border: none;
        color: #d01b2a;
    }
    
    header .menu .right-menu li:last-child {
        margin-right: 0px;
    }
    
    header .menu .right-menu li i {
        padding-right: 3px;
        font-size: 16px;
        vertical-align: middle;
    }
    
    .myprojects_link,
    .favorite_link {
        color: #9ea8af;
    }
    
    .myprojects_link.active i:before {
        color: #6f75a9;
    }
    
    .favorite_link.active i:before {
        color: #de1500;
    }
    
    header .menu a {
        color: #000000;
        font-size: 14px;
    }
    
    header .menu a:hover {
        color: #d01b2a;
    }
    
    header .menu a.active {
        border-bottom: 3px solid #22a2b5;
        padding-bottom: 11px;
        text-decoration: none;
    }
    
    header .menu .icon-chevron-down {
        font-size: 11px;
        font-weight: 100;
        color: #aeaeae;
    }
    /*header .menu a.active:before {*/
    /*content: "";*/
    /*width: 128px;*/
    /*height: 3px;*/
    /*background-color: #22a2b5;*/
    /*position: absolute;*/
    /*bottom: -16px;*/
    /*}*/
    
    header .menu .list-menu {
        content: "";
        width: 182px;
        background-color: #ffffff;
        text-align: left;
        line-height: 1;
        padding-top: 17px;
        padding-bottom: 22px;
        padding-left: 22px;
        border-radius: 4px;
        position: absolute;
        top: -14px;
        left: -8px;
        box-shadow: 0 3px 15px 0 rgba(56, 60, 62, 0.35);
        cursor: auto;
        display: none;
        z-index: 1;
    }
    
    header .menu .list-menu a {
        margin-bottom: 16px;
        margin-right: 0;
        display: block;
        /*display: inline-block;*/
    }
    
    header .menu .list-menu a:last-child {
        margin-bottom: 0;
    }
    /* Класс конструктора. Должен идти сразу после тега body, в конструкторе */
    
    .modal-open #top_menu {
        /*opacity: 0.4;*/
    }
    #background-list {}

    /* Template sprites */
    #template-list {
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        /* для Safari < 9 */
        display: -webkit-flex;
        -webkit-flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -webkit-align-items: center;
    }
    
    #template-list .template-item {
        background: url(/img/sprite_artboards_visitcards_@1x.png) no-repeat;
        width: 88px;
        min-width: 88px;
        height: 48px;

        display: -webkit-inline-flex;
    }

    #template-list .template-item.vertical {
        width: 48px;
        min-width: 48px;
        height: 88px;
    }

    #template-list .template-item.sprite-17 {
        background-position: 0 -68px;
    }
    #template-list .template-item.sprite-17:hover,
    #template-list .template-item.sprite-17.active {
        background-position: 0 -168px;
    }

    #template-list .template-item.sprite-16 {
        background-position: -100px -68px;
    }
    #template-list .template-item.sprite-16:hover,
    #template-list .template-item.sprite-16.active {
        background-position: -100px -168px;
    }

    #template-list .template-item.sprite-18 {
        background-position: -200px -68px;
    }
    #template-list .template-item.sprite-18:hover,
    #template-list .template-item.sprite-18.active {
        background-position: -200px -168px;
    }

    #template-list .template-item.sprite-19 {
        background-position: -300px -68px;
    }
    #template-list .template-item.sprite-19:hover,
    #template-list .template-item.sprite-19.active {
        background-position: -300px -168px;
    }

    #template-list .template-item.sprite-20 {
        background-position: -400px -68px;
    }
    #template-list .template-item.sprite-20:hover,
    #template-list .template-item.sprite-20.active {
        background-position: -400px -168px;
    }

    #template-list .template-item.sprite-21 {
        background-position: -500px -68px;
    }
    #template-list .template-item.sprite-21:hover,
    #template-list .template-item.sprite-21.active {
        background-position: -500px -168px;
    }

    #template-list .template-item.sprite-26 {
        background-position: -600px -68px;
    }
    #template-list .template-item.sprite-26:hover,
    #template-list .template-item.sprite-26.active {
        background-position: -600px -168px;
    }

    #template-list .template-item.sprite-27 {
        background-position: -700px -68px;
    }
    #template-list .template-item.sprite-27:hover,
    #template-list .template-item.sprite-27.active {
        background-position: -700px -168px;
    }

    #template-list .template-item.sprite-22 {
        background-position: -800px -48px;
    }
    #template-list .template-item.sprite-22:hover,
    #template-list .template-item.sprite-22.active {
        background-position: -800px -148px;
    }

    #template-list .template-item.sprite-23 {
        background-position: -860px -48px;
    }
    #template-list .template-item.sprite-23:hover,
    #template-list .template-item.sprite-23.active {
        background-position: -860px -148px;
    }

    #template-list .template-item.sprite-28 {
        background-position: -920px -48px;
    }
    #template-list .template-item.sprite-28:hover,
    #template-list .template-item.sprite-28.active {
        background-position: -920px -148px;
    }

    #template-list .template-item.sprite-24 {
        background-position: -992px -68px;
    }
    #template-list .template-item.sprite-24:hover,
    #template-list .template-item.sprite-24.active {
        background-position: -992px -168px;
    }

    #template-list .template-item.sprite-25 {
        background-position: -1092px -48px;
    }
    #template-list .template-item.sprite-25:hover,
    #template-list .template-item.sprite-25.active {
        background-position: -1092px -148px;
    }


    .template-item:not(:last-child) {
        margin-right: 16px;
    }

    .template-item:not(:last-child) {
        cursor: pointer;
    }
    
    .template-item.active svg,
    .template-item svg:hover {
        outline: 1px solid #22a2b5;
    }
    
    .template-item.active svg path,
    .template-item svg:hover path {
        fill: #22a2b5;
    }
    
    .template-item:first-child {
        /* margin-left: 2px; */
    }
    /* .template-item:last-child {
        margin-right: 0;
    } */
    
    .template-item svg {
        width: 88px;
        height: 48px;
        outline: 1px solid #aeaeae;
    }
    
    .template-item.vertical {
        margin-top: 0;
    }
    
    .template-item.vertical svg {
        width: 50px;
        height: 91px;
    }
    
    .background-item {
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 1px solid #ccc;
        /*margin-top: 10px;*/
        /*margin-left: 5px;*/
        border-radius: 4px;
        cursor: pointer;
        box-shadow: inset 0 0 0 1px #ffffff;
    }
    
    #top_menu_corners {
        height: 56px;
        max-width: 210px;
        border-left: 1px solid #475058;
        border-right: 1px solid #475058;
        padding: 15px 0 15px 20px;
        cursor: pointer;
        display: none;
    }
    
    #top_menu_corners img {
        margin-right: 18px;
    }
    
    #top_menu_corners a,
    #top_menu_corners span {
        color: #f5f5f5;
        font-size: 14px;
        line-height: 1;
    }
    
    #top_menu_switch label {
        font-weight: normal;
        left: 91px;
    }
    /* верхнее меню конструктора */
    
    article {}
    
    article h1,
    .about-design-block h1 {
        font-family: 'Roboto', sans-serif;
        font-size: 36px;
        font-weight: 300;
        color: #010101;
        margin-bottom: 27px;
    }
    
    .about-design-block h2 {
        font-family: 'Roboto', sans-serif;
        font-size: 23px;
        font-weight: 300;
        color: #010101;
        /*margin-bottom: 27px;*/
    }
    
    article.catalog {
        background-color: #ffffff;
    }
    
    article .card img:hover {
        /*box-shadow:0 0 0 128px rgba(0, 0, 0, 0.33) inset;*/
        /*z-index: 1000;*/
        /*opacity: 0.4;*/
    }
    
    article .card .mask,
    .about-design-block .card .mask {
        background-color: rgba(62, 74, 83, .85);
        height: 317px;
        position: absolute;
        width: 280px;
        top: 0;
        overflow: hidden;
        opacity: 0;
        padding-top: 0;
        -webkit-transition: opacity .2s linear .3s;
        -o-transition: opacity .2s linear .3s;
        transition: opacity .2s linear .3s;
    }
    article.catalog .card .mask {
        cursor:pointer;
    }

    article .card .in .mask {
        opacity: 1;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
    }

    article .card .mask .icons {
        min-height: 14px;
        overflow: hidden;
        padding: 20px 0 60px 0;
        margin-top: -20%;
        -webkit-transition: margin-top .2s linear .2s;
        -o-transition: margin-top .2s linear .2s;
        transition: margin-top .2s linear .2s;
    }

    article .card .in .mask .icons {
        margin-top: 0;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
    }

    article .card .mask .text,
    .about-design-block .card .mask .text {
        color: #e1eaf1;
        cursor: pointer;
        font-size: 13px;
        text-align: center;
        border: 1px solid #e1eaf1;
        border-radius: 30px;
        padding: 8px 20px;
        margin: 30% 15% 0;
        width: 195px;
        height: 38px;
        -webkit-transition: margin-top .2s linear .2s;
        -o-transition: margin-top .2s linear .2s;
        transition: margin-top .2s linear .2s;
    }

    article .card .in .mask .text {
        margin-top: 50%;
        -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
    }
    
    article .card .mask .text i,
    article .card .mask .text span {
        color: #e1eaf1;
        margin: 0 2px;
    }
    
    article .card .mask .text span {
        width: 118px;
        text-align: center;
        display: inline-block;
    }
    
    article .card .mask .text:hover,
    .about-design-block .card .mask .text:hover {
        background: #22a2b5;
        border-color: #22a2b5;
    }
    
    article .card .mask .text:hover i,
    article .card .mask .text:hover span {
        color: #fff;
    }
    
    article .card .mask .number {
        bottom: 34px;
        color: #e1eaf1;
        font-size: 12px;
        margin: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        opacity: 0;
        -webkit-transition: opacity .2s linear 0s;
        -o-transition: opacity .2s linear 0s;
        transition: opacity .2s linear 0s;
    }

    article .card .in .mask .number {
        opacity: 1;
        -webkit-transition-delay: .2s;
        -o-transition-delay: .2s;
        transition-delay: .2s;
    }

    article.order .bcard.horizontal .mask {
        background-color: rgba(0, 0, 0, .7);
        height: 271px;
        position: absolute;
        width: 487px;
        top: 40px;
        left: 62px;
        margin-bottom: 3px;
    }
    
    article.order .bcard.horizontal .mask .text {
        color: #d3d3d3;
        font-size: 14px;
        text-align: center;
        border: 1px solid #d3d3d3;
        border-radius: 30px;
        padding: 8px 20px;
        margin: 25% 31%;
        width: 195px;
        height: 38px;
    }
    
    article.order .bcard.horizontal .mask .text i {
        margin-right: 10px;
        font-size: 16px;
    }
    
    article.order .bcard.vertical .mask {
        background-color: rgba(0, 0, 0, .7);
        height: 486px;
        position: absolute;
        width: 270px;
        top: 40px;
        left: 170px;
    }
    
    article.order .bcard.vertical .mask .text {
        color: #d3d3d3;
        font-size: 14px;
        text-align: center;
        border: 1px solid #d3d3d3;
        border-radius: 30px;
        padding: 8px 20px;
        margin: 77% 16%;
        width: 195px;
        height: 38px;
    }
    
    article.order .bcard.vertical .mask .text i {
        margin-right: 10px;
        font-size: 16px;
    }

    article .btn-default:link,
    article .btn-default:visited,
    article .btn-default:hover,
    article .btn-default:active,
    article .btn-default {
        width: 280px;
        min-width: 280px;
        height: 48px;
        padding: 0;
        color: white !important;
        background-color: #22a2b5;
        border-color: #22a2b5 !important;
        border-radius: 5px;
        outline: none !important;
    }
    
    article .btn-default:hover:not(.disabled),
    article .btn-default:hover:not(.selectedColor),
    article .btn-lightseagreen:hover:not(.disabled) {
        background-color: rgb(26, 127, 142) !important;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
    }
    
    article .btn-default>i {
        font-size: 24px;
        margin-left: 20px;
        line-height: 1.2;
    }
    
    article .btn-default>span {
        vertical-align: super;
        font-size: 16px;
        padding: 16px 34px 16px 8px;
        line-height: 2;
    }
    
    article .bcard_quantity {
        /*margin-left: -15px;*/
    }
    
    article .bcard_all {
        /*margin-right: 204px;*/
    }
    
    article .bcard-block {
        padding-top: 12px;
        margin-bottom: 76px;
        /*margin-left:   24px;*/
    }
    
    article .bcard-block .row:first-child {
        margin-bottom: 0;
    }
    
    article #block.bcard-block .card:nth-child(3n+3) {
        margin-right: 0;
    }
    
    article #projects.bcard-block .card:nth-child(4n+4),
    article #favorites.bcard-block .card:nth-child(4n+4) {
        margin-right: 0;
    }
    
    article .bcard-block .margin {
        margin-top: 14px;
    }
    
    article #projects.bcard-block {
        padding-top: 0;
            padding-left: 31px;
    }
    
    article #projects.bcard-block .card,
    article #favorites.bcard-block .card {
        margin-top: 24px;
    }
    
    article .bcard-block:not(#block) .card {
        /*margin-left: 15px;*/
        margin-right: 20px;
    }
    
    article #projects.bcard-block .row:last-child {
        margin-top: 0;
    }
    
    article i.hr {
        margin-left: 16px;
        margin-right: 16px;
        height: 100%;
        width: 1px;
        border: none;
        border-left: 1px solid #9d9d9d;
    }
    
    .pagination_before,
    .pagination_before:hover {
        /*margin-left: -15px;*/
        color: #9d9d9d;
        cursor: default;
        text-decoration: none;
        pointer-events: none;
    }
    
    article .pagination_two {
        display: inline-block;
        margin-bottom: 0px;
        /*width: 136px;*/
        /*height: 19px;*/
        /*overflow: hidden;*/
    }
    
    article .pagination_two li {
        display: inline;
        margin-right: 4px;
    }
    
    .pagination_two>a,
    .pagination_two>span,
    .pagination_two>a:hover,
    .pagination_two>span:hover,
    .pagination_two>a:focus,
    .pagination_two>span:focus {
        /*color: #ff0000;*/
    }
    
    .pagination_two>.active>a,
    .pagination_two>.active>span,
    .pagination_two>.active>a:hover,
    .pagination_two>.active>span:hover,
    .pagination_two>.active>a:focus,
    .pagination_two>.active>span:focus {
        z-index: 3;
        color: #000000;
        background-color: #d3d3d3;
        border-radius: 2px;
        padding: 1px 6px;
        cursor: default;
        pointer-events: none;
        text-decoration: none;
    }
    
    #left_nav {
        width: 280px;
        border-radius: 3px;
        margin-right: 0;
        margin-top: 10px;
        padding: 0 0 20px 0;
    }
    
    #left_nav ul.first {
        margin-top: 19px;
    }
    
    .left_nav_category {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: #555555;
    }
    
    #left_nav .icon-plus,
    #left_nav .icon-minus {
        font-family: 'Roboto', sans-serif;
        font-size: 9px;
        margin-top: 21px;
        margin-right: 13px;
        top: -1px;
        left: 4px;
    }
    
    #left_nav a {
        color: #22a2b5;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        text-decoration: none;
    }
    
    #left_nav a:hover {
        color: #de1500;
    }
    
    #left_nav_category_number {}
    
    #left_nav hr {
        border-color: #e5e5e5;
        margin: 21px 0 24px 0;
    }
    
    .button_block {
        color: #555555;
        border-radius: 3px;
        margin-bottom: 10px;
    }
    
    .circlec {
        padding-top: 7px;
        padding-bottom: 3px;
    }
    
    .circlec:before {
        color: #22a2b5;
        font-size: 10px;
        margin-left: 20px;
        margin-right: 10px;
    }
    
    .button_block .title {
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        background: #6f75a9;
        padding: 10px;
        padding-left: 20px;
    }
    
    .button_block .description {
        font-size: 14px;
        margin-top: 13px;
        margin-bottom: 10px;
    }
    
    .button_block img {
        width: 148px;
        height: 125px;
        font-size: 13px;
        margin-bottom: 14px;
    }
    
    .button_block.create {
        margin-bottom: 102px;
        padding-bottom: 32px;
        box-shadow: 0px 4px 10px 0px rgba(64, 80, 90, 0.4);
        border-radius: 3px;
        overflow: hidden;
        background: #f6f6f6;
    }
    
    article #block {}
    
    article #block .row {
        margin-bottom: 2px;
    }
    
    article .card {
        background: #b8b8b8;
    }
    
    article .card,
    .about-design-block .card {
        display: inline-block;
        width: 280px;
        min-width: 280px;
        margin-right: 19px;
        margin-top: 18px;
        position: relative;
    }
    
    article .card img,
    .about-design-block .card img {
        width: 280px;
        min-width: 280px;
        min-height: 317px;
        height: 317px;
    }
    
    article .card>div:not(.checkbox-on-image),
    .about-design-block .card>div:not(.checkbox-on-image) {
        width: 280px;
        height: 42px;
        color: #FFF;
        background: #353c41;
        /*left: 15px;*/
        bottom: 0;
        padding: 10px 0 13px 18px;
        margin: 0;
    }
    
    article .card:last-child {
        /*margin-right: 0;*/
    }
    
    article .card .view {
        color: #e5e5e5;
    }
    
    article .card .view:hover {
        color: #de1500;
    }
    
    article .card .text {
        width: 73%;
        float: left;
        white-space: nowrap;
        /* Отменяем перенос текста */
        overflow: hidden;
        /* Обрезаем содержимое */
        text-overflow: ellipsis;
        /* Многоточие */
    }
    
    article .card .commands,
    .about-design-block .card .commands {
        padding-right: 21px;
        line-height: 1.5;
        float: right;
        position: relative;
    }
    
    article .card .icon-heart,
    article .card .icon-heart-o,
    article .card .icon-share-alt,
    article .card .icon-trash,
    article .card .icon-copy,
    article .card .icon-eye {
        font-size: 1.2em;
        margin-left: 12px;
        color: #d3d3d3;
        cursor: pointer;
    }
    
    article .card .icon-heart:hover,
    article .card .icon-heart-o:hover,
    article .card .icon-share-alt:hover,
    article .card .icon-trash:hover {
        color: #ffffff;
    }
    
    article .card .icon-heart {
        color: #DE1500;
    }
    
    article .card .icon-share-alt {
        display: none;
    }
    /* конструктор */
    
    .constructor article {
        margin-top: 24px;
        /*margin-bottom: 75px;*/
        background: #f6f5f3;
        min-height: 502px;
    }
    
    .constructor #format_color_block {
        height: 166px;
        min-width: 1240px;
        width: 100%;
        background-color: #ffffff;
        border-bottom: 1px solid #e5e5e5;
        display: none;
        position: relative;
    }
    
    .constructor #format_color_block .close {
        position: absolute;
        right: 24px;
        top: 14px;
        font-size: 14px;
        opacity: 0.8;
    }
    
    .constructor #format_color_block .close:hover {
        opacity: 1;
    }

    @media (max-width: 1286px) {
        .constructor #format_color_block .close {
            right: -moz-calc(100% - 1246px);
            right: -webkit-calc(100% - 1246px);
            right: calc(100% - 1246px);
        }
    }

    @media (max-width: 1270px) {
        .constructor #format_color_block .close {
            right: 8px;
        }
    }


    .constructor #size_menu,
    .constructor #format_menu,
    .constructor #color_background {
        /*height: 167px;*/
        display: none;
        margin: 0;
        padding-top: 17px;
        /*padding-bottom: 20px;*/
        background: #ffffff;
        text-align: center;
    }
    
    .constructor #format_menu .designs-row {
        display: flex;
        flex-direction: row;
        padding: 0 1px;
        padding-bottom: 26px;

        display: -webkit-flex;
    }
    
    .constructor #format_menu .designs-row .ready {
        display: flex;
        align-items: center;
        padding: 0;

        /* для Safari < 9*/
        display: -webkit-flex;
        -webkit-align-items: center;
    }

    .constructor #format_menu .designs-row .ready .text {
        margin-right: 15px;
    }
    .constructor #format_menu .designs-row .ready .template-ready {
        margin-right: 40px;
    }
    
    .constructor #format_menu .designs-row .ready .template-ready img {
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
        max-width: 88px;
        max-height: 88px;
    }
    .template-item.template-ready img {
        cursor: pointer;
    }

    .constructor #format_menu .designs-row .options-row {
        display: flex;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;

        display: -webkit-flex;
    }
    
    .constructor #format_menu .designs-row .options-text {
        padding: 0px;
        margin-right: 15px;
        padding-top: 30px;
    }
    
    .constructor #format_menu .text {
        font-size: 13px;
        color: #909aa0;
        line-height: 1.3;
        padding-left: 0;
    }
    
    .constructor #size_menu .close,
    .constructor #format_menu .close,
    .constructor #color_background .close {
        margin: 0;
        background: #ffffff;
        text-align: center;
        font-size: 14px;
        opacity: 1;
        position: absolute;
        right: 24px;
        font-weight: normal;
    }
    
    .constructor #format_menu {
        padding-left: 0;
        padding-right: 0;
    }
    
    .constructor #format_menu .arrow {
        position: absolute;
        top: 51px;
        right: -51px;
    }
    
    .constructor #color_background .arrow {
        position: absolute;
        top: 14px;
        right: 0;
    }
    
    .constructor #size_menu {}
    
    .constructor #size_menu .text {
        font-size: 13px;
        margin-bottom: 8px;
    }
    
    .constructor #size_menu .description,
    .constructor #format_menu .description,
    .constructor #color_background .description {
        margin-top: 5px;
    }
    
    .constructor #size_menu .icon-info-circle,
    .constructor #format_menu .icon-info-circle,
    .constructor #color_background .icon-info-circle {
        font-size: 22px;
        color: #22a2b5;
    }
    
    .constructor #size_menu .size-block {
        width: 64px;
        height: 50px;
        margin-top: 26px;
        margin-right: 36px;
        cursor: pointer;
        float: left;
    }
    
    .constructor #size_menu .size-block:last-child {
        margin-right: 0;
    }
    
    .constructor #size_menu .size-block.active .square-horizontal,
    .constructor #size_menu .size-block.active .square-vertical {
        border: 2px solid #22a2b5;
    }
    
    .constructor #size_menu .size-block.active .text {
        color: #22a2b5;
    }
    
    .constructor #size_menu .square-horizontal {
        border: 2px solid #aeaeae;
        width: 26px;
        height: 14px;
        margin: 6px 18px 18px 18px;
    }
    
    .constructor #size_menu .square-vertical {
        border: 2px solid #aeaeae;
        width: 14px;
        height: 26px;
        margin: 0 25px 12px 25px;
    }
    
    .constructor #format_menu .btn-group {
        margin-bottom: 22px;
        /*display: none;*/
    }
    /*
    .constructor #menu_face_turn_side {
        padding-top:    20px;
        padding-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }
    .constructor #menu_face_turn_side .row {
        margin-left:   0;
        margin-right:  0;
        padding-left:  0;
        padding-right: 0;
    }
    .constructor #menu_face_turn_side .row > div {
        padding-left: 0;
    }
    .constructor #menu_face_turn_side .left-button-block button {
        margin-right: 12px;
    }
    .constructor #menu_face_turn_side .right-button-block button {
        margin-left: 12px;
    }*/
    
    #modal_change_save {
        font-size: 13px;
        position: absolute;
        top: 58px;
        left: 15px;
        z-index: 999 !important;
        width: 302px;
        min-height: 64px;
        border-radius: 4px;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
        background: #ffffff;
        cursor: pointer;
        display: none;
        border: 1px solid #dadada;
    }
    
    #modal_change_save .saveMenu {
        margin-bottom: 0px;
    }
    
    #modal_change_save .saveMenu .saveMenu__el {
        height: 32px;
        padding: 8px 0px 5px 15px;
        border-bottom: 1px solid #e5e5e5;
    }

    #modal_change_save .saveMenu .saveMenu__el:hover {
       background-color: #e9fbfe;
    }

    #modal_change_save .saveMenu .saveMenu__el:last-child {
        border: none;
    }
    /*******Для незарегистрированных пользователей***/
    
    #modal_change_save.info {
        padding: 8px 0 8px 19px;
    }
    
    .constructor #modal_change_save.info2 .icon {
        margin: 0px 0 3px;
    }
    /* Стрелка сверху */
    
    #modal_change_save.info:after,
    #modal_change_save.info:before {
        bottom: 100%;
        left: 5%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    /* без Стрелки сверху */
    
    #modal_change_save.info2:after,
    #modal_change_save.info2:before {
        content: none;
    }
    
    #modal_change_save.info:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #22a2b5;
        border-width: 7px;
        margin-left: -7px;
    }
    
    #modal_change_save.info:before {
        border-color: rgba(218, 218, 218, 0);
        border-bottom-color: #dadada;
        border-width: 8px;
        margin-left: -8px;
    }
    
    #modal_change_save:after,
    #modal_change_save:before {
        bottom: 100%;
        left: 5%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    #modal_change_save:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 7px;
        margin-left: -7px;
    }
    
    #modal_change_save:before {
        border-color: rgba(218, 218, 218, 0);
        border-bottom-color: #dadada;
        border-width: 8px;
        margin-left: -8px;
    }
    
    .constructor #modal_change_save .change {
        /*padding: 12px 0 0 47px;*/
        padding: 0;
        float: left;
    }
    
    .constructor #modal_change_save .hot_key {
        color: #768085;
        padding-right: 16px;
        float: right;
    }
    
    .constructor #modal_change_save hr {
        margin: 16px 0 !important;
        top: 9px;
        color: #e5e5e5;
        clear: both;
        position: relative;
    }
    
    .constructor #modal_change_save a {
        color: #22a2b5;
    }
    
    .constructor #modal_change_save a:hover {
        color: #de1500;
    }
    
    .popover.fade {
        background: #ffe9e7;
        border: 1px solid #e44e3e;
        width: 100%;
        z-index: 1004;
    }
    
    .popover.fade.right {
        width: 100%;
    }
    
    .popover.left>.arrow {
        border-left-color: #de1500;
    }
    
    .popover.left>.arrow:after {
        border-left-color: #ffe9e7;
    }
    
    .popover.right>.arrow {
        border-right-color: #de1500;
    }
    
    .popover.right>.arrow:after {
        border-right-color: #ffe9e7;
    }
    
    .popover.top>.arrow {
        border-top-color: #de1500;
    }
    
    .popover.top>.arrow:after {
        border-top-color: #ffe9e7;
    }
    
    .popover.bottom>.arrow {
        border-bottom-color: #de1500;
    }
    
    .popover.bottom>.arrow:after {
        border-bottom-color: #ffe9e7;
    }
    /* Id применяется когда лицо активно, class когда активен оборот (#face,#turn и .face,.turn) */
    
    .unactiveFace {
        border-bottom: 1px dotted #555555;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    .unactiveFace:hover {
        color: #22a2b5;
        border-bottom: 1px dotted #22a2b5;
    }
    
    .activeFace {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    #face {
        font-size: 0.8em;
        font-weight: bold;
        text-transform: uppercase;
        margin-right: 15px;
    }
    
    #turn {
        border-bottom: 1px dotted #555555;
        text-transform: uppercase;
        margin-left: 15px;
    }
    
    .face {
        font-size: 1.1em !important;
        font-weight: normal !important;
        border-bottom: 1px dotted #555555;
    }
    
    .turn {
        font-size: 0.8em;
        font-weight: bold;
        border-bottom: 0 !important;
    }
    
    #menu_face_turn_side .icon-arrow_360 {
        font-size: 1.4em;
        line-height: 0.5;
        vertical-align: middle;
        margin-left: 33px;
        margin-right: 26px;
    }
    
    #menu_face_turn_side .text-right {
        /*padding-right: 0;*/
    }
    
    .constructor article#menu_face_turn_side .row div {
        /*padding-left: 8px;*/
    }
    
    .constructor #left_nav {
        border: none;
        right: 1px;
    }
    
    .constructor .message_success,
    .constructor .message_info,
    .constructor .message_error {
        width: 100%;
        border: 1px solid #de1500;
        border-radius: 4px;
        background-color: #ffe9e7;
        position: relative;
    }
    
    .constructor .message_success .filename,
    .constructor .message_info .filename,
    .constructor .message_error .filename {
        font-weight: 700;
    }
    
    .constructor #modal_change_save>.icon,
    .constructor .message_success>.icon,
    .constructor .message_info>.icon,
    .constructor .message_error>.icon {
        width: 32px;
        height: 100%;
        padding: 14px 8px;
        border-radius: 2px 0 0 2px;
        text-align: center;
        background: #FF3300;
        /*float: left;*/
        position: absolute;
    }
    
    .constructor .message_success>div,
    .constructor .message_info>div,
    .constructor .message_error>div {
        margin-bottom: 0 !important;
    }
    
    .constructor #modal_change_save>div>i,
    .constructor .message_success>div>i,
    .constructor .message_info>div>i,
    .constructor .message_error>div>i {
        font-size: 20px;
        color: #fff;
        opacity: 0.8;
    }
    
    .constructor .message_success>.message,
    .constructor .message_info>.message,
    .constructor .message_error>.message {
        font-size: 13px;
        line-height: 14px;
        padding: 16px 17px 15px 54px;
    }
    
    .constructor .message_error>.message>.icon {
        color: #22a2b5;
        font-size: 16px;
        font-weight: bold;
        margin-top: -2px;
        margin-right: 8px;
        float: right;
    }
    
    .constructor .message_error>.message>.show,
    .constructor .message_error>.message>.edit {
        color: #de1500;
        border-bottom: 1px #de1500 solid;
        text-decoration: none;
        float: right;
        cursor: pointer;
    }
    
    .constructor .message_success>.message>.show,
    .constructor .message_info>.message>.show {
        color: #22a2b5;
        border-bottom: 1px #22a2b5 dotted;
        text-decoration: none;
        float: right;
        cursor: pointer;
    }
    
    .constructor .message_success>.message>.show:hover,
    .constructor .message_info>.message>.show:hover {
        color: #de1500;
        border-bottom: 1px #de1500 dotted;
    }
    
    .constructor .message_info {
        width: 100%;
        border: 1px solid #f39200;
        background-color: #fff3e1;
    }
    
    .constructor .message_info>.icon {
        width: 32px;
        /*height: 46px;*/
        padding: 12px 8px;
        border-radius: 2px 0 0 2px;
        text-align: center;
        background: #f39200;
        float: left;
    }
    
    .constructor .order .message_info>.icon {
        height: 100%;
    }
    
    .constructor .order #info.message_info>.icon {
        padding: 56px 0;
    }
    
    .constructor .message_success {
        width: 100%;
        border: 1px solid #22a2b5;
        background-color: #c6effb;
    }
    
    .constructor .message_success>.icon {
        width: 32px;
        height: 47px;
        padding: 12px 8px;
        border-radius: 2px 0 0 2px;
        text-align: center;
        background: #22a2b5;
        float: left;
    }
    
    .constructor #modal_change_save>.icon {
        width: 32px;
        height: 64px;
        padding: 22px 8px;
        margin: -8px -20px -1px -20px;
        border-radius: 4px 0 0 4px;
        text-align: center;
        background: #22a2b5;
        float: left;
    }
    
    .constructor #modal_change_save>.text {
        padding: 4px 0 0 30px;
        float: left;
    }
    /*Стрелка вправо*/
    
    div.arrow-right {
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left-color: #ffffff;
        border-right: 0;
        display: inline-block;
        position: absolute;
        top: 10px;
        right: -8px;
    }
    /*Стрелка вниз*/
    
    .modal_share_mini:after,
    .modal_share_mini:before {
        top: 99.9%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    .modal_share_mini:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 10px;
        margin-left: -24px;
    }
    
    .modal_share_mini:before {
        border-color: rgba(218, 218, 218, 0);
        border-top-color: #dadada;
        border-width: 11px;
        margin-left: -25px;
    }
    /*Стрелка вниз*/
    
    #workspace {
        margin-top: 25px;
        margin-bottom: 104px;
    }
    #workspace > .item-holder {
        position: relative;
    }

    #workspace .inner {
        width: 100%;
        height: 100%;
        border: 2mm solid rgba(222, 222, 222, 0.56);
        z-index: 90;
        position: relative;
        pointer-events: none;
    }
    #workspace .innerimg {
        border: 1px dotted #999;
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        z-index: 10;
    }
    #workspace .innerimg.left {
        margin-left: 0 !important;
    }
    #workspace .innerimg.top {
        margin-top: 0 !important;
    }
    #workspace .innerimg.right {
        margin-right: 0 !important;
    }
    #workspace .innerimg.bottom {
        margin-bottom: 0 !important;
    }

    #workspace .incontainertext, #workspace .incontainerimg {
        cursor: pointer;
        /*overflow: hidden;*/
        position: absolute;
    }
    #workspace .incontainerimg {
        overflow: hidden;
    }

    #workspace .incontainerimg2 {
        cursor: default;
        overflow: hidden;
        position: absolute;
    }
        
    #workspace .incontainerimg > img  {
        cursor: move;
        position: absolute;
    }

    #workspace .incontainerimg2{
        pointer-events: none;
    }

    #workspace .incontainerimg2 > img {
        cursor: default;
        position: relative;
        pointer-events: none;
    }

    #workspace .incontainerimg.empty::after,
    #workspace .incontainerimg:hover::after {
        content: attr(data-hovertext);
        position: absolute;
        margin: auto;
        display: inline-block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 22px;
        text-align: center;
        white-space: pre;
        word-wrap: break-word;
        z-index: 998;
        pointer-events: none;
        font-size: 13px;
        opacity: 0.5;
        color: #999;
    }


    #workspace .incontainerimg:hover::before {
        content: " ";
        position: absolute;
        margin: auto;
        display: inline-block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        text-align: center;
        white-space: pre;
        word-wrap: break-word;
        pointer-events: none;
        background: rgba(255, 255, 255, 0.4);
        z-index : 999;
    }

    #workspace .incontainertext > div {
        width: 100%;
        min-height: 100%;
        opacity: 0;
    }

    #workspace .incontainertext > div:focus {
      position: absolute;
      z-index: 100000;
    }
    #workspace .item-content:hover .incontainertext.text{
        border-bottom: 1px dotted #dedede;
    }

    #workspace .item-content .incontainertext.text:hover {
         border-bottom: 1px solid #aaaaaa;
    }


     #workspace .item-content:hover .incontainertext.textarea{
        border: 1px dotted black;
    }

    #workspace .item-content .incontainertext.textarea:hover {
         border: 1px solid black;
    }

    #workspace .incontainertext.text > input {
        background: white;
        cursor: text;
        opacity: 1;
        box-sizing: content-box;
        padding: 6px;
        border: 1px solid #ccc;
        margin: -6px 0 0 -6px;
        outline: none;
        width: auto;
        vertical-align: text-bottom;
        height: auto;

    }

    #workspace .incontainertext.textarea > textarea {
        background: white;
        cursor: text;
        opacity: 1;
        box-sizing: content-box;
        padding: 6px;
        border: 1px solid #ccc;
        margin: -6px 0 0 -6px;
        outline: none;
        width: auto;
        vertical-align: text-bottom;
    }

    #workspace input {
        width: 100%;
        border: none;
    }

    .borderbottom {
        border-bottom: 1px solid black;

    }


    #workspace #upload_file {
        background: #d3d3d3;
        padding-top: 25px;
        padding-bottom: 25px;
        margin-top: -50px;
        margin-bottom: -30px;
    }
    
    #workspace #upload_image {
        font-size: 3em;
        margin: 0;
        padding-top: 20px;
    }
    
    #workspace #upload_text {
        padding-bottom: 20px;
    }
    
    #workspace #map {
        width: 50%;
        float: left;
    }
    
    #workspace #map_inputs {
        width: 50%;
        float: left;
    }
    
    #workspace .slider-nav img {
        width: 100%;
        max-height: 280px;
    }
    
    #workspace svg {
        box-shadow: 0 8px 20px 10px #cbcbcb;
    }
    
    #workspace .lSSlideWrapper {
        max-width: 100%;
        overflow: hidden;
        position: relative;
    }
    
    #workspace .lSSlideOuter {
        margin-left: 8px;
        margin-top: 16px;
    }
    
    #workspace .lSAction>.lSNext {
        right: 14px;
    }
    
    #workspace .bcard-icon-warning {
        width: 26px;
        height: 26px;
        padding: 0;
        border-radius: 20px;
        font-size: 22px;
        line-height: 1.183;
        background: #ffffff;
        color: #de1500;
    }
    
    .item-content.items {
        position: relative;
        margin: auto;
            pointer-events: auto;
    }
    
    #next_side {
        /*margin-top: 54px;*/
    }
    
    #next_side>div {
        padding-left: 0;
        /*padding-right: 0;*/
    }
    
    #next_side .switch {
        margin-top: 12px;
    }
    
    #next_side .switch .text {
        left: 48px;
    }
    
    .constructor #next_side .checkbox {
        max-width: 150px;
        display: inline-block;
    }
    
    .constructor #next_side .btn-default {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        float: right;
        outline: none;
    }
    
    .constructor #next_side .btn-lightseagreen {
        width: 172px;
        height: 40px;
    }
    
    .constructor #next_side .btn-lightseagreen i {
        font-weight: bold;
        margin-left: 13px;
        color: #fff !important;
    }
    
    .constructor .right_nav {
        display: none;
        margin-top: 31px;
        padding: 16px;
        width: 256px;
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
        background: #ffffff;
        position: absolute;
        z-index: 900;
    }

    .right_nav:after,
    .right_nav:before {
        right: 100%;
        top: 34px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    .right_nav:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 10px;
        margin-top: -10px;
    }
    
    .right_nav:before {
        border-color: rgba(220, 220, 218, 0);
        border-right-color: #dcdcda;
        border-width: 11px;
        margin-top: -11px;
    }

    .constructor .right_nav > div {
        float: left;
        margin-top: 8px;
        position: relative;
    }
    .constructor .right_nav#image-form > div {
        margin-top: 16px;
    }

    .constructor .right_nav button {
        float: left;
        margin-left: 8px;
    }
    
    .constructor .right_nav .valign-block {
        margin-bottom: 8px;
        margin-left: 64px;
    }


    .constructor .right_nav .button-with-text,
    .constructor .right_nav .button-square,
    .constructor .right_nav #font-element,
    .constructor .right_nav #color-elementtext,
    .constructor .right_nav #text-font-size {
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #aeaeae;
        border-radius: 3px;
        -webkit-box-shadow: none;
        box-shadow: none;
        position: relative;
    }

    .constructor .right_nav .button-with-text {
        text-align: left;
        width: 143px;
    }

    .constructor .right_nav .button-with-text > i {
        font-size: 16px;
        margin-right: 14px;
        vertical-align: middle;
    }

    .constructor .right_nav .button-square > i {
        font-size: 14px;
    }

    .constructor .right_nav .button-with-text > .text {
        font-size: 13px;
    }

    .constructor .right_nav#image-form > div button:first-child {
        margin-left: 0;
    }

    .constructor .right_nav#image-form > div.align-block {
        margin-top: 8px;
    }

    .constructor .right_nav .align-block .button-group {
        margin-top: 8px;
        overflow: hidden;
    }

    .constructor .right_nav .move-block {
        margin-left: 42px;
    }

    .constructor .right_nav .move-block:before {
        content: " ";
        display: block;
        position: absolute;
        background: #fff;
        width: 30px;
        height: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
    }

    .constructor .right_nav .image-move {
        margin-left: 0;
        padding: 0;
    }

    .constructor .right_nav .image-move i {
        font-size: 20px;
    }

    .constructor .right_nav .image-move.move-up {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        height: 20px;
        padding: 0 5px;
        clear: both;
        margin-left: 19px !important;
    }

    .constructor .right_nav .image-move.move-left {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 20px;
        padding: 5px 0;
        clear: left;
    }

    .constructor .right_nav .image-move.move-right {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 20px;
        padding: 5px 0;
        clear: right;
        margin-left: 30px;
    }

    .constructor .right_nav .image-move.move-down {
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        height: 20px;
        padding: 0 5px;
        clear: both;
        margin-left: 19px;
    }


    .constructor .right_nav #font-element,
    .constructor .right_nav #color-elementtext,
    .constructor .right_nav #text-font-size {
        font-size: 13px;
        height: 32px;
        line-height: 32px;
    }

    .constructor .right_nav #font-element {
        margin-bottom: 8px;
        margin-top: 0;
        width: 224px;
    }

    .constructor .right_nav #color-elementtext,
    .constructor .right_nav #text-font-size {
        float: left;
        width: 64px;
    }

    .constructor .right_nav .button-with-text:not(.disabled):hover,
    .constructor .right_nav .button-square:not(.disabled):not(.active):hover,
    .constructor .right_nav #font-element:hover,
    .constructor .right_nav #color-elementtext:hover,
    .constructor .right_nav #text-font-size:hover,
    .button-square:hover {
        box-shadow: 0 2px 5px 0 rgba(56, 60, 62, 0.25);
    }

    .constructor .right_nav .button-with-text.active,
    .constructor .right_nav .button-square.active,
    .constructor .right_nav #font-element.active,
    .constructor .right_nav #color-elementtext.active,
    .constructor .right_nav #text-font-size.active {
        background-color: #f6f5f3;
        border: 1px solid #aeaeae;
        -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .constructor .right_nav .button-with-text.active:not(.disabled):hover,
    .constructor .right_nav #font-element.active:hover,
    .constructor .right_nav #color-elementtext.active:hover,
    .constructor .right_nav #text-font-size.active:hover,
    .button-square.active:hover {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 2px 5px 0 rgba(56, 60, 62, 0.25);
    }

    .constructor .right_nav #font-element .icon-arrow_drop_down,
    .constructor #text-form #color-elementtext .icon-arrow_drop_down,
    .constructor .right_nav #text-font-size .icon-arrow_drop_down {
        position: absolute;
        right: 0;
        top: 4px;
        font-size: 23px;
        color: #aeaeae;
        pointer-events: none;
    }

    .constructor .right_nav #font-element .chance {
        height: 30px;
        line-height: 30px;
    }

    .constructor .right_nav #font-element .chance img,
    .constructor .right_nav #text-font-size .chance span {
        margin-left: 15px;
    }

    .constructor .right_nav #text-font-size .chance .color {
        margin: 3px;
    }

    .constructor .right_nav #font-element img,
    .constructor .right_nav #text-font-size span {
        margin-left: 20px;
    }

    .constructor .right_nav #font-element img {
        height: 11px;
        max-width: 192px;
    }

    .constructor .right_nav #font-element .image-element,
    .constructor .right_nav #text-font-size .text-element {
        cursor: pointer;
        height: 24px;
        line-height: 24px;
    }

    .constructor .right_nav #font-element .image-element {
        width: 212px;
    }

    .constructor .right_nav #text-font-size .text-element {
        width: 52px;
    }

    .constructor .right_nav #color-elementtext .color {
        height: 24px;
        width: 24px;
        margin: 2px;
        display: block;
        cursor: pointer;
        float: left;
        border: 1px solid #aeaeae;
        border-radius: 3px;
        box-shadow: inset 0 0 0 1px #ffffff;
    }

    .constructor .right_nav #color-elementtext .chance .color {
        margin: 3px;
    }

    .constructor .right_nav #font-element .image-element:hover,
    .constructor .right_nav #text-font-size .text-element:hover {
        background: #e5e5e5;
    }
    
    .constructor .right_nav #font-element .image-element .icon-check,
    .constructor .right_nav #text-font-size .text-element .icon-check {
        display: none;
        font-size: 11px;
        line-height: 24px;
        padding: 2px 0 0 2px;
        position: absolute;
        text-align: center;
        width: 20px;
    }
    
    .constructor .right_nav #font-element .chance .icon-check {
        display: none;
    }

    .overlay-panel {
        left: -1px;
        position: absolute;
        overflow-y: auto;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        z-index: 1000;
        display: none;
    }

    .overlay-border {
        pointer-events: none;
        background: #fff;
        border-radius: 3px;
        height: 4px;
        width: 100%;
        position: absolute;
        z-index: 1001;
        display: none;
    }

    .constructor .right_nav #font-element .overlay-panel,
    .constructor .right_nav #text-font-size .overlay-panel {
        height: 269px;
        padding: 4px 0;
    }

    .constructor .right_nav #font-element .overlay-panel {
        margin-top: 3px;
        width: 224px;
    }
    .constructor .right_nav #font-element .overlay-border.overlay-top {
        margin-top: 4px;
    }
    .constructor .right_nav #font-element .overlay-border.overlay-bottom {
        margin-top: 267px;
    }

    .constructor .right_nav #color-elementtext .overlay-panel {
        cursor: default;
        margin-top: 33px;
        padding: 27px;
        width: 224px;
        height: 280px;
    }

    .constructor .right_nav #text-font-size .overlay-panel {
        margin-top: 1px;
        width: 64px;
    }
    .constructor .right_nav #text-font-size .overlay-border.overlay-top {
        margin-top: 2px;
    }
    .constructor .right_nav #text-font-size .overlay-border.overlay-bottom {
        margin-top: 265px;
    }

/*
    .constructor #image-form.right_nav .text:first-child {
        margin-top: 0;
        margin-bottom: 16px;
    }
    
    .constructor #image-form.right_nav .text {
        font-size: 13px;
        color: #909090;
        margin-top: 14px;
        margin-bottom: 14px;
    }

    .constructor #image-form.right_nav .button-group>button {
        margin-right: 12px;
    }
*/

    /* Слайдер в редакторе изображений */
    
    .constructor #image-form.right_nav #slider {
        float: left;
        height: 4px !important;
        margin: 14px 8px 0 16px;
        width: 128px;

    }

    .constructor #image-form.right_nav #slider span {
        background: #22a2b5;
        width: 21px !important;
        height: 21px !important;
        border-radius: 20px;
        margin-top: 3px;
        margin-left: -11px;
        border: 1px solid #22a2b5;
        box-shadow: inset 0 0 0 3px #ffffff;
    }
    
    .constructor #image-form.right_nav #image-form form {
        margin: 0;
    }
    
    .constructor #image-form.right_nav .button-group:last-child {
        margin-bottom: 0;
    }
    
    .constructor .right_nav #map_button {
        width: 100%;
    }
    
    .constructor .right_nav .button-square .icon-rotate_right {
        margin-left: -1px;
    }
    
    .constructor .slider.slider-horizontal {
        width: 100%;
    }
    
    .constructor .slider-handle.round {
        background: #22a2b5;
    }


    .constructor .right_nav .btn-lightseagreen {
        border-radius: 3px;
        font-size: 13px;
        height: 32px;
        margin: 16px 0 0 0;
        padding: 4px;
        position: relative;
        text-align: center;
        width: 100%;
    }

    .constructor .right_nav .btn-lightseagreen i {
        font-size: 18px;
        left: 8px;
        position: absolute;
    }

    .constructor .right_nav .btn-lightseagreen .text {
        margin: 0;
    }

    /* конструктор */
    /* Обзор макета перед заказом */
    
    .constructor .order {
        margin-top: 0;
    }
    
    .constructor .order h1 {
        font-size: 36px;
        font-weight: normal;
        text-align: left;
        margin: 36px 0 25px 0;
    }
    
    .constructor .order .bcard {
        height: auto;
        background: transparent;
        margin: 40px 0 64px 0;
    }
    
    .constructor .order .bcard img {
        box-shadow: -4px 4px 20px 5px #c6c9ca;
        max-width: none;
    }
    
    .constructor .order .bcard .error {
        height: 28px;
        width: 28px;
        padding: 7px;
        left: 40px;
        top: 22px;
        font-size: 14px;
        color: #F08080;
        background: #ff0000;
        border-radius: 3px;
        position: absolute;
        display: none;
    }
    
    .constructor .order .header-title {
        font-weight: bold;
        margin-top: 24px;
        margin-bottom: 8px;
        color: #000000;
    }
    
    .constructor .order .header-body {
        margin-bottom: 12px;
        font-size: 12px;
    }
    
    .constructor .order .header-body .error {
        margin-bottom: 16px;
        cursor: pointer;
    }
    
    .constructor .order .header-body .error .error_message {}
    
    .constructor .order .header-body .error .error_message i.icon-warning.error {
        color: #ff0000;
        margin-right: 8px;
    }
    
    .constructor .order .header-body .error .error_message_continuation {
        margin-left: 26px;
    }
    
    .constructor .order .checkbox label::before {
        border: 2px solid #A9A9A9;
        width: 20px;
        height: 20px;
    }
    
    .constructor .order .checkbox label::after {
        font-size: 13px;
    }
    
    .constructor .order .checkbox label {
        font-weight: bold;
        font-size: 14px;
        padding-left: 12px;
        padding-bottom: 8px;
        color: #555555;
        line-height: 1.4;
    }
    
    .constructor .order .checkbox label:hover {
        color: #555555;
    }
    
    .constructor .order .btn-lightseagreen.mini.disabled {
        padding: 0 14px;
        height: 40px;
        width: 195px;
        background-color: #a2a2a2;
        border: 1px solid #a2a2a2;
    }
    
    .constructor .order .btn-lightseagreen.mini.disabled:hover {
        background-color: #a2a2a2 !important;
        box-shadow: none;
    }
    
    .constructor .order .btn-lightseagreen.mini {
        padding: 0 14px;
        height: 40px;
        width: 195px;
        background-color: #22a2b5;
        border: 1px solid #22a2b5;
    }
    
    .constructor .order .btn-lightseagreen.mini:hover {
        background-color: #22a2b5;
        box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
    }
    
    .constructor .order .btn-lightseagreen.mini i {
        font-size: 14px;
        line-height: 2;
        margin: 0;
    }
    
    .constructor .order .btn-lightseagreen.mini span {
        font-size: 14px;
        line-height: 2;
        margin-right: 8px;
        color: #e5e5e5;
    }
    
    .constructor .order .btn-transparent.mini {
        border: 1px solid #A9A9A9;
        padding: 0 13px;
        height: 35px;
    }
    
    .constructor .order .btn-transparent.mini i {
        font-size: 14px;
        line-height: 2;
        margin: 0;
    }
    
    .constructor .order .btn-transparent.mini span {
        font-size: 13px;
        line-height: 2;
        margin-right: 8px;
    }
    
    .constructor .order hr {
        border-top: 1px solid #e5e5e5;
        margin-top: 8px;
        margin-right: 8px;
        margin-bottom: 0;
    }
    
    .constructor .order .padding {
        padding-top: 12px;
        line-height: 1.3;
    }
    
    .constructor .order #info.message_info {
        margin-bottom: 104px;
    }
    
    .constructor .order #info.message_info .message {
        padding-top: 15px;
        padding-right: 26px;
        padding-bottom: 26px;
    }
    
    .constructor .order .message_info .message .checkbox-category {
        padding-right: 23px;
        padding-bottom: 8px;
    }
    /* Страница о дизайне */
    
    .design-page {
        min-width: 1436px;
    }
    
    .jumbotron {
        background-color: transparent;
        margin: 0;
        padding: 0;
    }
    
    .jumbotron h1 {
        font-size: 36px;
        font-weight: 400;
    }
    
    .jumbotron .text-standout {}
    
    .jumbotron-left p {
        max-width: 350px;
    }
    
    .jumbotron p {
        font-size: 13px;
        margin-bottom: 10px;
    }
    
    .design-page .container-fluid .col-xs-12.padding {
        padding: 0;
    }
    
    .slideshow-overlay,
    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
    }
    
    .slideshow-overlay .btn-lightseagreen {
        margin-top: 20px;
    }
    
    .container .jumbotron-left {
        padding-top: 90px;
        padding-left: 10px;
        text-align: left;
    }
    
    .about-design {}
    
    .about-design-block {
        border: 1px solid #e6e6e6;
        border-radius: 2px;
        min-height: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .about-design-block:not(.others) a {
        font-size: 14px;
        font-weight: bold;
        color: #22a2b5;
        display: inline-block;
        margin-bottom: 20px;
        text-decoration: none;
    }
    
    .about-design-block.others {
        margin-bottom: 104px;
        padding-bottom: 20px;
    }
    
    #myCarousel .carousel-inner {
        height: 428px;
        overflow: hidden;
    }
    
    #myCarousel .carousel-inner .item {}
    
    #myCarousel .carousel-inner .item img {
        height: 428px;
    }
    /* Страница о дизайне */
    /* Мои проекты */
    /*FilterMenu*/
    
    #filtersMenu {
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 3px 15px 0px rgba(56, 60, 62, 0.35);
        position: absolute;
        right: 18px;
        top: 60px;
        z-index: 1000;
        display: none;
    }
    
    #filtersMenu ul {
        margin-bottom: 8px;
    }
    
    #filtersMenu ul li {
        display: block;
        padding: 10px 30px 6px 22px;
        cursor: pointer;
    }
    
    #filtersMenu ul li:hover {
        background-color: #e9fbfe;
    }
    
    #filtersMenu ul li.menuItem.active {
        background-color: #e9fbfe;
    }
    
    .projects {
        background: #fff !important;
        margin-top: 0 !important;
    }
    
    .projects h2 {
        font-size: 32px;
        margin-top: 23px;
        margin-bottom: 0;
        padding-right: 9px;
    }
    
    .projects .filters {
        padding-top: 36px;
        position: relative;
    }
    
    .projects .filters .link {
        color: #22a2b5;
        cursor: pointer;
    }
    
    .projects .filters .link i {
        font-size: 20px;
        vertical-align: bottom;
        display: inline-block;
        color: #9ea8af;
        transition: transform .5s;
    }
    
    .projects .filters .link.open i {
        -webkit-transform: rotateX(180deg) translate(0px, -2px);
        -moz-transform: rotateX(180deg) translate(0px, -2px);
        -o-transform: rotateX(180deg) translate(0px, -2px);
        -ms-transform: rotateX(180deg) translate(0px, -2px);
        transform: rotateX(180deg) translate(0px, -2px);
        transform: rotateX(180deg) translate(0px, -2px);
    }
    
    .projects .message_info {
        top: 20px;
        right: 94px;
        position: absolute;
        z-index: 999;
    }

    .error-page {
        background-color: #6f75a9;
        color: white;
        left: 0;
        position: absolute;
        width: 100%;
        height: calc(100% - 290px);
    }
    .project_empty, .error-404 {
        text-align: center;
        margin: 100px auto;
        width: 620px;
    }

    .project_empty .btn-circle, .error-404 .btn-circle {
        margin-left: 0;
    }

    .project_empty .favorite-icon, .project_empty .project-icon, .error-404 .error-icon {
        background-color: #DE1500;
        border-radius: 55px;
        color: white;
        font-size: 50px;
        padding: 25px 28px 12px 28px;
    }
    .project_empty .project-icon {
        background-color: #6f75a9;
    }
    .error-404 .error-icon {
        background-color: black;
        font-size: 42px;
        font-weight: bold;
        padding: 24px 16px 24px 16px;
    }
    .error-404.error-500 .error-icon {
        background-color: white;
        font-size: 80px;
        padding: 0px 24px 0px 24px;
    }
    
    .project_empty .header-title, .error-404 .header-title {
        font-size: 36px;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 16px;
        margin-top: 35px;
    }
    .error-500 .header-title {
        margin-top: 24px;
    }
    .error-404:not(.error-500) .header-title {
        margin-top: 50px;
    }
    .error-500 .error-message {
        font-size: 18px;
    }
    .error-500 .error-code {
        font-weight: bold;
    }
    #send_error {
        margin-left: 24px;
    }
    .button-errors {
        margin-bottom: 36px;
    }
    
    .project_empty .header-body, .error-404 .header-body {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 36px;
        margin-top: 30px;
    }
    
    .project_empty .header-body a, .error-404 .header-body a {
        color: #22a2b5;
    }
    
    .project_empty button, .error-404 button {
        text-align: center;
        margin-top: 40px;
    }
    
    .project_empty button span, .error-404 button span {
        font-size: 14px;
    }
    /* Обзор макета перед заказом */
    
    .tag-page {
        margin-top: 20px;
    }
    
    .tag-page .forms-elementitem:focus {
        height: inherit;
    }
    /* Избранное */
    
    #favorites {
        padding-top: 0;
        padding-left: 31px;
    }
    
    #favorites .row:first-child {
        margin-top: 0;

    }
    
    .pdf {}
    
    .pdf .upload {
        width: 220px;
        height: 150px;
        display: inline-block;
        margin-right: 20px;
        margin-top: 20px;
    }
    
    footer {}
    
    #footer {
        position: absolute;
        bottom: 0;
        left: 0;
        /*right: 0;*/
        width: 100%;
        color: #ffffff;
        background-color: #353c41;
    }
    
    footer .container {
        font-size: 16px;
        height: 290px;
        padding-top: 38px;
        padding-bottom: 22px;
        background-color: #353c41;
        color: #e3e2e0;
    }
    
    footer div {
        color: #f6f5f3;
    }
    
    footer ul {
        /*list-style-type: none;*/
        /*margin-left:  0; /!* Отступ слева в браузере IE и Opera *!/*/
        /*padding-left: 0; /!* Отступ слева в браузере Firefox, Safari, Chrome *!/*/
        margin-top: 17px;
    }
    
    footer li {
        font-size: 13px;
        color: #e5e5e5;
        margin-bottom: 4px;
    }
    
    footer div a,
    footer div a:link,
    footer div a:visited,
    footer div a:active {
        text-decoration: none;
        color: #aeb2b5;
    }
    
    footer div a:hover {
        color: #ffffff;
    }
    
    footer .btn-circle {
        width: 24px !important;
        height: 24px !important;
        text-align: center;
        padding: 3px 0;
        margin: 20px 11px 0 0;
        border-radius: 15px;
        color: #353c41 !important;
        background-color: #eeeeee;
        font-size: 13px;
    }
    
    footer .btn-circle:hover {
        color: #000000 !important;
        background-color: #f6f5f3;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.75);
    }

    footer #phone a{
        font-weight: 600;
        font-size: 16px;
        color: #f6f5f3;
    }

    footer #phone {
        font-weight: 600;
        font-size: 16px;
        color: #f6f5f3;
    }
    
    footer #copyright {
        font-size: 13px;
        color: #e5e5e5;
        margin-top: 24px;
    }

    /* perfectScrollbar стиль */
    .ps-container {
        -ms-touch-action: auto;
        touch-action: auto;
        overflow: hidden !important;
        -ms-overflow-style: none;
    }
    
    .ps-container>.ps-scrollbar-y-rail {
        width: 4px;
        margin-top: 4px;
        margin-right: 4px;
        margin-bottom: 4px;
        opacity: 1;
        filter: alpha(opacity=100);
        background-color: #e5e5e5 !important;
        display: block;
    }
    
    .ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y {
        width: 4px !important;
        margin-right: -2px;
        margin-top: 0;
        color: #a2a2a2;
    }
    
    .ps-container>.ps-scrollbar-y-rail:hover>.ps-scrollbar-y,
    .ps-container>.ps-scrollbar-y-rail:active>.ps-scrollbar-y {
        width: 4px;
    }

    .ps-container:hover>.ps-scrollbar-x-rail,
    .ps-container:hover>.ps-scrollbar-y-rail {
        opacity: 1;
    }
    
    .ps-container:hover.ps-in-scrolling.ps-y>.ps-scrollbar-y-rail>.ps-scrollbar-y {
        background-color: #999;
        width: 4px;
    }

    .popup-basic-window {
        border-radius: 7px;
        background: #1abc9c;
        margin: 0 auto;
        overflow: visible;
        position: relative;
        padding-bottom: 1px;
        text-align: left;
        right: 0;
        bottom: 0;
        left: 0;
        width: 480px;
    }

    .popup-basic-window h1.popup-basic-title {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 30px;
        text-align: left;
        margin-left: 8px;
        padding-left: 30px;
    }

    .popup-basic-content {
        margin: 0 8px 8px;
        background: #ffffff;
        border-radius: 7px;
        padding: 0 30px 24px 30px;
        overflow: hidden;
        line-height: 18px;
        font-size: 16px;
        text-align: center;
    }

    .popup-basic-content p {
        color: #555;
        font-size: 14px;
        margin: 10px 0;
        text-align: left;
    }

    .popup-basic-content input {
        color: #000;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .popup-basic-content button:hover {
        width: inherit;
    }
    
    .popup-basic-content .closeWindowMessage:hover {
        color: #808080 !important;
    }
    
    .delete-confirm {
        background: #ffffff;
        border: 1px solid #dadada;
        box-shadow: 0px 10px 20px 0px rgba(64, 80, 90, 0.4);
        padding: 8px 0 0 0;
    }
    
    .delete-confirm>h1 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }
    
    .delete-confirm>.icon-close_popup {
        position: absolute;
        top: 13px;
        right: 13px;
        cursor: pointer;
    }
    
    .popup-basic-content button {
        margin-top: 10px;
    }
    
    .popup-basic-content .btn-default:active,
    .popup-basic-content .btn-default {
        border-radius: 20px;
        padding-left: 30px;
        padding-right: 30px;
        height: 40px;
        background: transparent;
        color: #808080;
    }
    
    .popup-basic-content .modalPrompt {
        color: #ffffff !important;
        background: #ff3300 !important;
        margin-right: 16px;
    }
    
    h1 {
        display: block;
        font-size: 2em;
        -webkit-margin-before: 0.67em;
        -webkit-margin-after: 0.67em;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        font-weight: bold;
    }
    
    .wgreen-btn {
        border: 2px solid #1abc9c;
        background: transparent;
        border-radius: 7px;
        padding: 0 15px 0 15px;
        height: 33px;
        line-height: 33px;
        display: block;
        color: #1abc9c;
        text-decoration: none;
        text-align: center;
        /* font-size: 16px; */
        font-size: 14px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    
    .red-btn {
        border: 2px solid #ea6060;
        border-radius: 7px;
        padding: 0 15px 0 15px;
        height: 33px;
        line-height: 33px;
        display: block;
        color: #ea6060;
        text-decoration: none;
        text-align: center;
        /* font-size: 16px; */
        font-size: 14px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    
    .image-backgrounds {
        margin-top: 16px;
        min-height: 73px;
        overflow: hidden;
    }
    
    .backnoactive {
        float: left;
        width: 100px !important;
        margin-right: 10px;
        margin-top: 10px;
    }
    
    .backnoactive.active {
        border-bottom: 4px solid #22a2b5;
        margin-bottom: -4px;
    }
    /* Слайдер изображений */
    
    .lightSlider {
        overflow: hidden;
        margin: 0;
        height: auto !important;
    }
    
    .lSAction>a {
        background-image: url(../img/controls.png);
        background-color: #22a2b5;
        border-radius: 20px;
    }
    
    .lSAction>.lSNext {
        background-position: -33px 0 !important;
        right: 10px;
    }
    
    .favprojdiv {
        text-align: center;
    }
    
    .projfavstyle {
        position: absolute;
        top: 5px;
        right: 12px;
    }
    
    .projfavview {
        position: absolute;
        top: 6px;
        right: 32px;
    }
    
    .projfavcopy {
        position: absolute;
        top: 6px;
        right: 58px;
    }
    /* Media */
    
    @media (max-width: 1240px) {
        header {
            width: 1240px;
        }
    }

    /*************** Normal popover*********************************/
    
    #popover_reg {
        display: none;
    }
    
    .popover-info {
        font-size: 13px;
        position: absolute;
        z-index: 999 !important;
        width: 278px;
        padding: 0px;
        border-radius: 4px;
        box-shadow: 0 2px 18px 0 rgba(56, 60, 62, 0.25);
        background: #ffffff;
        cursor: pointer;
        display: block;
    }
    
    .popover-info.pos_right {
        top: 0px;
        right: -300px;
    }
    
    .popover-info.pos_right:before {
        border: 8px solid transparent;
        border-right: 10px solid #22a2b5;
        content: "";
        position: absolute;
        left: -16px;
        top: 16px;
    }
    
    .popover-info .icon_block {
        width: 38px;
        height: inherit;
        padding: 14px 26px 38px 12px;
        border-radius: 4px 0 0 4px;
        text-align: center;
        background: #22a2b5;
        float: left;
    }
    
    .popover-info .icon_block i {
        font-size: 20px;
        color: #bff7fc;
    }
    
    .popover-info .content {
        padding: 12px 0 5px 54px;
    }
    
    #preloader,
    .imgloader {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 105;
        margin: 0 auto;
    }
    
    .imgloader {
        background: none !important;
        width: 100% !important;
        height: 100% !important;
    }
    
    .imgloader-modal {
        top: 60px;
        height: 99% !important;
    }
    
    .block_preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -16px 0 0 -25px;
        width: 50px;
        height: 50px;
    }
    
    .imgloader img.preloader {
        height: 50px !important;
        width: 50px !important;
        min-width: auto;
        min-height: auto;
    }
    /* #layouts_filelist li:last-child {
        margin: 0px !important;
    } */
    /***********Фиксированный размер окна*/
    
    #imageEditModal .modal-dialog {
        width: 900px !important;
    }

    /* preloader */
    #circularG {
        position: relative;
        width: 50px;
        height: 50px;
        margin: auto;
    }
    
    .circularG {
        position: absolute;
        background-color: rgb(82, 92, 100);
        width: 12px;
        height: 12px;
        border-radius: 8px;
        -o-border-radius: 8px;
        -ms-border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        animation-name: bounce_circularG;
        -o-animation-name: bounce_circularG;
        -ms-animation-name: bounce_circularG;
        -webkit-animation-name: bounce_circularG;
        -moz-animation-name: bounce_circularG;
        animation-duration: 0.868s;
        -o-animation-duration: 0.868s;
        -ms-animation-duration: 0.868s;
        -webkit-animation-duration: 0.868s;
        -moz-animation-duration: 0.868s;
        animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-direction: normal;
        -o-animation-direction: normal;
        -ms-animation-direction: normal;
        -webkit-animation-direction: normal;
        -moz-animation-direction: normal;
    }
    
    #circularG.grey .circularG {
        background-color: rgb(82, 92, 100);
    }
    
    #circularG.blue .circularG {
        background-color: #22a2b5;
    }
    
    #circularG_1 {
        left: 0;
        top: 20px;
        animation-delay: 0.328s;
        -o-animation-delay: 0.328s;
        -ms-animation-delay: 0.328s;
        -webkit-animation-delay: 0.328s;
        -moz-animation-delay: 0.328s;
    }
    
    #circularG_2 {
        left: 5px;
        top: 5px;
        animation-delay: 0.434s;
        -o-animation-delay: 0.434s;
        -ms-animation-delay: 0.434s;
        -webkit-animation-delay: 0.434s;
        -moz-animation-delay: 0.434s;
    }
    
    #circularG_3 {
        top: 0;
        left: 20px;
        animation-delay: 0.54s;
        -o-animation-delay: 0.54s;
        -ms-animation-delay: 0.54s;
        -webkit-animation-delay: 0.54s;
        -moz-animation-delay: 0.54s;
    }
    
    #circularG_4 {
        right: 5px;
        top: 5px;
        animation-delay: 0.646s;
        -o-animation-delay: 0.646s;
        -ms-animation-delay: 0.646s;
        -webkit-animation-delay: 0.646s;
        -moz-animation-delay: 0.646s;
    }
    
    #circularG_5 {
        right: 0;
        top: 20px;
        animation-delay: 0.752s;
        -o-animation-delay: 0.752s;
        -ms-animation-delay: 0.752s;
        -webkit-animation-delay: 0.752s;
        -moz-animation-delay: 0.752s;
    }
    
    #circularG_6 {
        right: 5px;
        bottom: 5px;
        animation-delay: 0.868s;
        -o-animation-delay: 0.868s;
        -ms-animation-delay: 0.868s;
        -webkit-animation-delay: 0.868s;
        -moz-animation-delay: 0.868s;
    }
    
    #circularG_7 {
        left: 20px;
        bottom: 0;
        animation-delay: 0.974s;
        -o-animation-delay: 0.974s;
        -ms-animation-delay: 0.974s;
        -webkit-animation-delay: 0.974s;
        -moz-animation-delay: 0.974s;
    }
    
    #circularG_8 {
        left: 5px;
        bottom: 5px;
        animation-delay: 1.08s;
        -o-animation-delay: 1.08s;
        -ms-animation-delay: 1.08s;
        -webkit-animation-delay: 1.08s;
        -moz-animation-delay: 1.08s;
    }
    
    @keyframes bounce_circularG {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(.3);
        }
    }
    
    @-o-keyframes bounce_circularG {
        0% {
            -o-transform: scale(1);
        }
        100% {
            -o-transform: scale(.3);
        }
    }
    
    @-ms-keyframes bounce_circularG {
        0% {
            -ms-transform: scale(1);
        }
        100% {
            -ms-transform: scale(.3);
        }
    }
    
    @-webkit-keyframes bounce_circularG {
        0% {
            -webkit-transform: scale(1);
        }
        100% {
            -webkit-transform: scale(.3);
        }
    }
    
    @-moz-keyframes bounce_circularG {
        0% {
            -moz-transform: scale(1);
        }
        100% {
            -moz-transform: scale(.3);
        }
    }
    /* END preloader */

    /* preloader for save maket modal*/
    #circleGbtn {
        display: none;
        position: relative;
        bottom: 20px;
        text-align: center;
    }
    .btn-wait span {
        visibility: hidden;
    }
    .btn-wait #circleGbtn {
        display: block;
    }

    .circleGbtn {
        background-color: #fff;
        opacity: 0;
        display: inline-block;
        height: 8px;
        margin: 0 4px;
        width: 8px;
        animation-name: bounce_circleG;
        -o-animation-name: bounce_circleG;
        -ms-animation-name: bounce_circleG;
        -webkit-animation-name: bounce_circleG;
        -moz-animation-name: bounce_circleG;
        animation-duration: 1.2s;
        -o-animation-duration: 1.2s;
        -ms-animation-duration: 1.2s;
        -webkit-animation-duration: 1.2s;
        -moz-animation-duration: 1.2s;
        animation-iteration-count: infinite;
        -o-animation-iteration-count: infinite;
        -ms-animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        -moz-animation-iteration-count: infinite;
        animation-direction: normal;
        -o-animation-direction: normal;
        -ms-animation-direction: normal;
        -webkit-animation-direction: normal;
        -moz-animation-direction: normal;
        border-radius: 12px;
        -o-border-radius: 12px;
        -ms-border-radius: 12px;
        -webkit-border-radius: 12px;
    }

    #circleG_1 {
        animation-delay: 0.2s;
        -o-animation-delay: 0.2s;
        -ms-animation-delay: 0.2s;
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
    }
    #circleG_2 {
        animation-delay: 0.4s;
        -o-animation-delay: 0.4s;
        -ms-animation-delay: 0.4s;
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
    }
    #circleG_3 {
        animation-delay: 0.6s;
        -o-animation-delay: 0.6s;
        -ms-animation-delay: 0.6s;
        -webkit-animation-delay: 0.6s;
        -moz-animation-delay: 0.6s;
    }

    @keyframes bounce_circleG {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
    @-o-keyframes bounce_circleG {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
    @-ms-keyframes bounce_circleG {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
    @-webkit-keyframes bounce_circleG {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
    @-moz-keyframes bounce_circleG {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
    /* END save maket modal preloader */
    
    .error-element {
        color: #f1ea63;
        cursor: help;
        left: 0;
        position: absolute;
        top: 0;
        text-align: center;
    }
    
    .error-element {
        z-index: 1003;
    }

    .skeletonimg {
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .disabledtext {
        cursor: default;
        color: #b5b5b5!important;
    }