/* основная часть конструктора */

.constructor #content {
    width: 100%;
    background-color: #f6f5f3;
    min-height: 550px;
}


/* основная часть конструктора */


/* фиксированное меню конструктора */

.constructor .constructor-menu {
    height: 65px;
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    min-width: 1240px;
}

.constructor .constructor-menu #top_menu .editor_btn {
    float: left;
    cursor: pointer;
}

.constructor .constructor-menu #left-button-block {
    padding-top: 16px;
    width: 286px;
    padding-left: 15px;
    float: left;
    position: relative;
}

.constructor .constructor-menu #top_menu #top_menu_format {
    width: 216px;
    padding-left: 15px;
    position: relative;
}

.constructor .constructor-menu #top_menu #top_menu_color_background {
    width: 216px;
    padding-left: 25px;
    position: relative;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
}

.constructor .constructor-menu #top_menu #top_menu_face {
    width: 260px;
}

.constructor .constructor-menu #top_menu .top_menu_format__hide {
    width: 216px;
    padding-left: 15px;
    position: relative;
    cursor: default;
}

.constructor .constructor-menu #top_menu .top_menu_format__hide {
    width: 216px;
    padding-left: 15px;
    position: relative;
    cursor: default;
}

.constructor .constructor-menu #top_menu #top_menu_btn_next {
    position: relative;
    width: 228px;
}

.constructor .constructor-menu #top_menu #top_menu_btn_next #next_side {
    display: inline-block;
    margin-top: -4px;
    vertical-align: middle;
}

.constructor .constructor-menu #top_menu #top_menu_btn_next .btn-lightseagreen {
    margin-top: auto;
    text-align: left;
}

.constructor .constructor-menu #top_menu #top_menu_btn_next .btn-lightseagreen i {
    top: 38%;
    right: 20px;
    font-size: 16px;
}

.constructor .constructor-menu #left-button-block .button-square {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #aeaeae;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    margin-right: 8px;
}

.constructor .constructor-menu #left-button-block .button-square > i {
    font-size: 14px;
}

.constructor .constructor-menu #left-button-block .button-square:not(.disabled):hover {
    box-shadow: 0 2px 5px 0 rgba(56, 60, 62, 0.25);
}

.constructor .constructor-menu #left-button-block .button-square a {
    text-decoration: none;
}


/* .constructor .constructor-menu .face_turn_side {
    padding-top: 22px;
    text-align: center;
} */


/* .face_turn_side {
    font-size: 1.1em;
    text-align: center;
    padding-top: 7px;
} */

.face_turn_side a {
    text-decoration: none;
}

.face_turn_side #faceEditor {
    font-size: 14px;
    position: absolute;
    left: 108px;
}

.face_turn_side #backEditor {
    font-size: 14px;
    position: absolute;
    right: 112px;
}

.face_turn_side .icon-arrow_360 {
    font-size: 24px;
    margin-left: -22px;
}


/* фиксированное меню конструктора */

ul.main-menu li {
    display: inline-block;
    margin-right: 36px;
}


/* верхнее меню конструктора */

#menu {
    width: 100%;
}

#top_menu {
    margin-left: 0;
    margin-right: 0;
    height: 65px;
}

#top_menu .switch {
    padding: 17px 0 10px 20px;
}

#top_menu_size {
    height: 56px;
    max-width: 194px;
    border-left: 1px solid #475058;
    /*border-right: 1px solid #475058;*/
    padding: 16px 0 16px 32px;
    cursor: pointer;
}

#top_menu_size .icon,
#top_menu_color_background .icon {
    margin-right: 18px;
    width: 24px;
    height: 24px;
    background: url(/img/sprite_icons_@1x.png) no-repeat;
    background-position: 0 0;
    float: left;
}

#top_menu_size span {
    color: #f5f5f5;
    font-size: 14px;
    line-height: 1.7;
}

#top_menu_format {
    border-left: 1px solid #dadada;
    cursor: pointer;
}

#top_menu_format .preview {
    width: 48px;
    height: 24px;
    border: 1px solid red;
    display: inline-block;
    vertical-align: middle;
}

#top_menu_face {
    cursor: pointer;
}

#top_menu_face .arrow {
    display: inline-block;
    width: 32px;
}

#top_menu_face .arrow i {
    position: relative;
    top: 6px;
    right: 1px;
    margin-right: 2px;
    font-size: 20px;
    color: #22a2b5;
}

#top_menu_format .icon {
    width: 44px;
    height: 44px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 44px;
}

#top_menu_format .icon img,
#top_menu_format .icon div {
    width: 44px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
}

#top_menu_format .icon img.vertical,
#top_menu_format .icon div.vertical {
    width: 24px;
    height: 44px;
}

#top_menu_format .icon img {
    box-shadow: 0 3px 10px 0 rgba(56, 60, 62, 0.25);
}

#top_menu_format .icon div {
    background: url(/img/sprite_artboards_visitcards_@1x.png) no-repeat;
}

#top_menu_format .icon div.sprite-17 {
    background-position: 0 -10px;
}

#top_menu_format .icon div.sprite-16 {
    background-position: -100px -10px;
}

#top_menu_format .icon div.sprite-18 {
    background-position: -200px -10px;
}

#top_menu_format .icon div.sprite-19 {
    background-position: -300px -10px;
}

#top_menu_format .icon div.sprite-20 {
    background-position: -400px -10px;
}

#top_menu_format .icon div.sprite-21 {
    background-position: -500px -10px;
}

#top_menu_format .icon div.sprite-26 {
    background-position: -600px -10px;
}

#top_menu_format .icon div.sprite-27 {
    background-position: -700px -10px;
}

#top_menu_format .icon div.sprite-22 {
    background-position: -800px 0;
}

#top_menu_format .icon div.sprite-23 {
    background-position: -860px 0;
}

#top_menu_format .icon div.sprite-28 {
    background-position: -920px 0;
}

#top_menu_format .icon div.sprite-24 {
    background-position: -992px -10px;
}

#top_menu_format .icon div.sprite-25 {
    background-position: -1092px 0;
}


#eraserbutton2 {
    max-width: 194px;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    cursor: pointer;
}

#previewformatproject {
    max-width: 280px;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    cursor: pointer;
}

#top_menu_color_background .color-block {
    vertical-align: middle;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px #ffffff;
}


/*******CART BTN******/

.rightBlock {
    line-height: 1;
}

#cart .btncart {
    border: 1px solid #22a2b5;
    display: block;
    float: left;
}

#cart .btncart i {
    color: #22a2b5;
    font-size: 24px;
}

#cart .info {
    margin-left: 11px;
    display: inline-block;
    text-align: left;
    line-height: 1.4;
}

#cart .info .orders {
    color: black;
    font-size: 13px;
}

#cart .info .cost {
    font-weight: 700;
    font-size: 16px;
}

.cartBlock {
    display: inline-block;
    padding-right: 24px;
}

.loginBlock {
    display: inline-block;
}

#authorized {
    display: inline-block;
}


/*---editor links---*/

.editor_btn {
    height: 100%;
    line-height: 65px;
    padding-left: 21px;
    padding-right: 0px;
}

.editor_btn .text {
    margin-left: 8px;
}

.editor_btn i {
    font-size: 12px;
    color: #aeaeae;
    display: inline-block;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transition: transform .4s;
    -webkit-transition: transform .4s;
    position: absolute;
    top: 43%;
    right: 10px;
}

.editor_btn.hideborder {
    border-bottom: 1px solid #fff;
}

.editor_btn.active.open {
    border-bottom: 1px solid #fff;
}

.editor_btn.open i {
    border-bottom: 1px solid #fff;
    transform: rotate(180deg);
    -webkit-transform: rotate(-180deg);
    transition: transform .4s;
    -webkit-transition: transform .4s;
}