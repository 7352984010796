.backfon:not(.active) {
    display: none;
}

.disabled {
    opacity: 0.5;
}

.preview:not(.disabled) {
    cursor: pointer;
}

.modal-body .bcard img {
    /*height: 265px;*/
}

.modal-backdrop {
    background-color: #fff;
}


/*******For bootstrap bug*********/

body {
    padding-right: 0 !important
}

.constructor.modal-open {
    overflow-y: auto!important;
}