/* Overlays */
.ui-widget-content {
    border: 1px solid #aaaaaa;
    color: #2c4359;
}

/* Interaction states
----------------------------------*/
.ui-state-default, .ui-widget-content .ui-state-default {
    border: none;
    font-weight: bold;
    color: #333333;
    outline: none;
}

.ui-state-default {
    background-size: 100% !important;
    cursor: pointer !important;
    top: -12px !important;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus {
    border: none;
    font-weight: bold;
    color: #212121;
    outline: none;
}

.ui-state-active, .ui-widget-content .ui-state-active {
    border: none;
    font-weight: bold;
    color: #ffffff;
    outline: none;
}

/* Progressbar
----------------------------------*/
.ui-progressbar {
    height: 2em;
    text-align: left;
}

.ui-progressbar .ui-progressbar-value {
    margin: -1px;
    height: 100%;
}

/* Slider
----------------------------------*/
.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 16px;
    height: 16px;
    cursor: default;
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em;
}

.ui-slider-handle {
    width: 16px !important;
    height: 30px !important;
}

.ui-widget-content {
    /*top: 15px;*/
    border: 1px solid #e6e6e6 !important;
    border-radius: 50px !important;
    height: 5px !important;
    background-color: #e6e6e6 !important;
}