@font-face {
    font-family: 'gm_constructor';
    src: url('/fonts/gm_constructor.eot?acxtbc');
    src: url('/fonts/gm_constructor.eot?acxtbc#iefix') format('embedded-opentype'),
        url('/fonts/gm_constructor.ttf?acxtbc') format('truetype'),
        url('/fonts/gm_constructor.woff?acxtbc') format('woff'),
        url('/fonts/gm_constructor.svg?acxtbc#gm_constructor') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'gm_constructor' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-error:before {
    content: "\e953";
    color: #de1500;
}
.icon-done:before {
    content: "\e93a";
    color: #95c11f;
}
.icon-format_a4:before {
    content: "\e93b";
    color: #5e6266;
}
.icon-format_a5:before {
    content: "\e956";
    color: #5e6266;
}
.icon-format_a6:before {
    content: "\e958";
    color: #5e6266;
}
.icon-format_dl:before {
    content: "\e959";
    color: #5e6266;
}
.icon-inbox:before {
    content: "\e94c";
}
.icon-close:before {
    content: "\e94d";
}
.icon-search-plus:before {
    content: "\e92e";
}
.icon-search:before {
    content: "\e94e";
}
.icon-bars:before {
    content: "\e92f";
}
.icon-user:before {
    content: "\e92c";
}
.icon-shopping-cart:before {
    content: "\e92d";
}
.icon-save:before {
    content: "\e900";
}
.icon-eye:before {
    content: "\e901";
}
.icon-eye-slash:before {
    content: "\e917";
}
.icon-print:before {
    content: "\e902";
}
.icon-undo:before {
    content: "\e903";
}
.icon-redo:before {
    content: "\e904";
}
.icon-eraser:before {
    content: "\e905";
}
.icon-flip_vertical:before {
    content: "\e906";
}
.icon-zoom-out:before {
    content: "\e95b";
}
.icon-zoom-in:before {
    content: "\e95c";
}
.icon-flip_horizontal:before {
    content: "\e907";
}
.icon-rotate_left:before {
    content: "\e908";
}
.icon-rotate_right:before {
    content: "\e909";
}
.icon-align_left:before {
    content: "\e90a";
}
.icon-align_right:before {
    content: "\e90b";
}
.icon-align_center:before {
    content: "\e90c";
}
.icon-fullscreen:before {
    content: "\e90d";
}
.icon-image:before {
    content: "\e90e";
}
.icon-copy:before {
    content: "\e957";
}
.icon-files-o:before {
    content: "\e957";
}
.icon-delete:before {
    content: "\e90f";
}
.icon-font:before {
    content: "\e91c";
}
.icon-bold:before {
    content: "\e910";
}
.icon-italic:before {
    content: "\e911";
}
.icon-underline:before {
    content: "\e912";
}
.icon-align-left2:before {
    content: "\e913";
}
.icon-align-center:before {
    content: "\e914";
}
.icon-align-right2:before {
    content: "\e915";
}
.icon-align-justify:before {
    content: "\e95d";
}
.icon-align-left:before {
    content: "\e95e";
}
.icon-align-horizontal-middle:before {
    content: "\e95f";
}
.icon-align-right:before {
    content: "\e960";
}
.icon-align-top:before {
    content: "\e961";
}
.icon-align-vertical-middle:before {
    content: "\e962";
}
.icon-align-bottom:before {
    content: "\e963";
}
.icon-valign-top:before {
    content: "\e94f";
}
.icon-valign-middle:before {
    content: "\e950";
}
.icon-valign-bottom:before {
    content: "\e951";
}
.icon-check-square-o:before {
    content: "\e916";
}
.icon-paper-plane:before {
    content: "\e918";
}
.icon-user-circle-o:before {
    content: "\e919";
}
.icon-pencil:before {
    content: "\e91b";
}
.icon-exclamation-triangle:before {
    content: "\e926";
}
.icon-warning:before {
    content: "\e926";
}
.icon-exclamation-circle:before {
    content: "\e939";
}
.icon-info-circle:before {
    content: "\e91a";
}
.icon-check-circle:before {
    content: "\e964";
}
.icon-check:before {
    content: "\e91d";
}
.icon-map-marker:before {
    content: "\e91e";
}
.icon-edit:before {
    content: "\e91f";
}
.icon-chain:before {
    content: "\e920";
}
.icon-link:before {
    content: "\e920";
}
.icon-share-alt:before {
    content: "\e921";
}
.icon-layers:before {
    content: "\e94a";
}
.icon-heart-o:before {
    content: "\e922";
}
.icon-heart:before {
    content: "\e923";
}
.icon-plus:before {
    content: "\e925";
}
.icon-minus:before {
    content: "\e924";
}
.icon-arrow_drop_down:before {
    content: "\e927";
}
.icon-arrow_drop_up:before {
    content: "\e928";
}
.icon-arrow_drop_right:before {
    content: "\e965";
}
.icon-arrow_drop_left:before {
    content: "\e966";
}
.icon-cloud-upload:before {
    content: "\e92a";
}
.icon-arrow_360:before {
    content: "\e929";
}
.icon-close_popup:before {
    content: "\e92b";
}
.icon-close_popup_14:before {
    content: "\e952";
    color: #555;
}
.icon-chevron-left:before {
    content: "\e930";
}
.icon-chevron-right:before {
    content: "\e931";
}
.icon-chevron-down:before {
    content: "\e932";
}
.icon-chevron-up:before {
    content: "\e933";
}
.icon-arrow-long-right:before {
    content: "\e934";
}
.icon-rotate:before {
    content: "\e95a";
}
.icon-chevron-thin-left:before {
    content: "\e954";
}
.icon-chevron-thin-right:before {
    content: "\e955";
}
.icon-pinterest-with-circle:before {
    content: "\e947";
    color: #bd081c;
}
.icon-yandex-with-circle:before {
    content: "\e948";
    color: #f00;
}
.icon-google-plus-circle:before {
    content: "\e94b";
}
.icon-vk-with-circle:before {
    content: "\e935";
}
.icon-mailru-with-circle:before {
    content: "\e949";
    color: #f89c0e;
}
.icon-facebook-with-circle:before {
    content: "\e936";
}
.icon-twitter-with-circle:before {
    content: "\e937";
}
.icon-instagram-with-circle:before {
    content: "\e938";
}
