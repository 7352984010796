.checkbox {
    padding-left: 20px;
    /*height: 16px;*/
}
.checkbox label {
    /*height:     16px;*/
    min-height: 16px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 4px;
    line-height:  1.2;
    color: #555555;
}
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width:  16px;
    height: 16px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}
.checkbox label:hover {
    color: #de1500;
}
.checkbox-format {
}
.checkbox-format input {
    vertical-align: middle;
    margin: 0;
}
.checkbox-format label {
}
.checkbox-format img {
}

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";}
.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
    display: block;
    content: "";
    width: 10px;
    height: 3px;
    background-color: #555555;
    border-radius: 2px;
    margin-left: -16.5px;
    margin-top: 7px;
}
.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
    cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
    opacity: 0.65; }
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed; }
.checkbox.checkbox-circle label::before {
    border-radius: 50%; }
.checkbox.checkbox-inline {
    margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: #337ab7;
    border-color: #337ab7; }
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
    color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
    background-color: #d9534f;
    border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
    color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
    color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
    color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c; }
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
    color: #fff;}

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
    background-color: #337ab7;
    border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
    background-color: #d9534f;
    border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
    background-color: #5bc0de;
    border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
    background-color: #5cb85c;
    border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
    background-color: #fff;
}

.radio {
    padding-left: 20px; }
.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px; }
.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out; }
.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.radio input[type="radio"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1); }
.radio input[type="radio"]:disabled {
    cursor: not-allowed;
}
.radio input[type="radio"]:disabled + label {
    opacity: 0.65; }
.radio input[type="radio"]:disabled + label::before {
    cursor: not-allowed; }
.radio.radio-inline {
    margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
    background-color: #337ab7; }
.radio-primary input[type="radio"]:checked + label::before {
    border-color: #337ab7; }
.radio-primary input[type="radio"]:checked + label::after {
    background-color: #337ab7; }

.radio-danger input[type="radio"] + label::after {
    background-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::before {
    border-color: #d9534f; }
.radio-danger input[type="radio"]:checked + label::after {
    background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
    background-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::before {
    border-color: #5bc0de; }
.radio-info input[type="radio"]:checked + label::after {
    background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
    background-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f0ad4e; }
.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
    background-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::before {
    border-color: #5cb85c; }
.radio-success input[type="radio"]:checked + label::after {
    background-color: #5cb85c; }

.checkbox-category input[type="checkbox"]:checked + label::before,
.checkbox-category input[type="radio"]:checked + label::before {
    background-color: #22a2b5;
    border-color: #22a2b5;
}
.checkbox-category input[type="checkbox"]:checked + label::after,
.checkbox-category input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-format input[type="checkbox"]:checked + label::before,
.checkbox-format input[type="radio"]:checked + label::before {
    background-color: #22a2b5;
    border-color: #22a2b5;
}
.checkbox-format input[type="checkbox"]:checked + label::after,
.checkbox-format input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-on-image {
    position: absolute!important;
    z-index: 1!important;
    left: 26px;
}
.checkbox-on-image input[type="checkbox"]:checked + label::before,
.checkbox-on-image input[type="radio"]:checked + label::before {
    background-color: #22a2b5;
    border-color: #22a2b5;
}
.checkbox-on-image input[type="checkbox"]:checked + label::after,
.checkbox-on-image input[type="radio"]:checked + label::after {
    color: #fff;
}

.checkbox-format input[type="checkbox"]:checked + label::before,
.checkbox-format input[type="radio"]:checked + label::before {
    background-color: #22a2b5;
    border-color: #22a2b5; }
.checkbox-format input[type="checkbox"]:checked + label::after,
.checkbox-format input[type="radio"]:checked + label::after {
    color: #fff; }

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
    font-family: 'gm_constructor';
    content: "\e91d";
}
input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
    color: #fff;
}
input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
    color: #fff;
}


/* IOS style */
.switch input[type="checkbox"] {
    position: absolute;
    opacity: 0;
}

/* Normal Track */
.switch input[type="checkbox"].ios-switch + div {
    vertical-align: middle;
    width:  39px;
    height: 18px;
    border: 1px solid #d3d3d3;
    border-radius: 999px;
    background-color: #A9A9A9;
    -webkit-transition-duration: .4s;
    -webkit-transition-property: background-color, box-shadow;
    box-shadow: inset 0 0 0 0 rgba(0,0,0,0.4);
    cursor: pointer;
    /*margin: 15px 1.2em 15px 2.5em;*/
}

/* Checked Track (Blue) */
.switch input[type="checkbox"].ios-switch:checked + div {
    width: 39px;
    background-position: 0 0;
    background-color: #22a2b5;
    border: 1px solid #1a7f8e;
    /*box-shadow: inset 0 0 0 10px rgba(59,137,259,1);*/
}

/* Normal Knob */
.switch input[type="checkbox"].ios-switch + div > div {
    float:  left;
    width:  18px;
    height: 18px;
    border-radius: inherit;
    background: #ffffff;
    margin-top:  -1px;
    /*margin-left: -1px;*/
    box-shadow: 0 1px 2px 0 #808080;
    -webkit-transition-timing-function: cubic-bezier(.54,1.85,.5,1);
    -webkit-transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color, box-shadow;
    -moz-transition-timing-function: cubic-bezier(.54,1.85,.5,1);
    -moz-transition-duration: 0.4s;
    -moz-transition-property: transform, background-color;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.4);*/
    pointer-events: none;
}

/* Checked Knob (Blue Style) */
.switch input[type="checkbox"].ios-switch:checked + div > div {
    -webkit-transform: translate3d(20px, 0, 0);
    -moz-transform: translate3d(20px, 0, 0);
    background-color: #ffffff;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(8, 80, 172,1);*/
    box-shadow: 0 1px 2px 0 #808080;
}

.switch .text {
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    position: absolute;
    margin-top:  -2px;
    /*margin-left: 10px;*/
    left: 75px;
    cursor: pointer;
}
